export const extrairGruposDoTokenGA = (tokenGA: string): string[] => {
  try {
    const base64Url = tokenGA.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
    const decodedToken = JSON.parse(jsonPayload);
    
    if (decodedToken.grupos) {
      return decodedToken.grupos;
    }

    const roles = decodedToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
    if (roles) {
      const rolesArray = JSON.parse(roles);
      const grupos: any[] = [];
      rolesArray.forEach((roleObj: { roles: any[]; }) => {
        roleObj.roles.forEach(role => {
          grupos.push(role.roleName);
        });
      });
      return grupos;
    }

    return [];
  } catch (error) {
    alert("Erro ao decodificar o token GA:" + error);
    return [];
  }
};
