export function FormatarData(dateTimeStr: string | null): string | null {
    if (!dateTimeStr) return null;
    const [date] = dateTimeStr.split(' ');
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
}

export function FormatarDataBrasileira(data: string): string {
    const [ano, mes, dia] = data.split('-');
    return `${dia}/${mes}/${ano}`;
};