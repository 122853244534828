import http from "./http";
import { AxiosError } from "axios";

interface Client {
  post<T, R>(url: string, payload: T, config?: object): Promise<R>;
  put<T, R>(url: string, payload: T, config?: object): Promise<R>;
  get<T, R>(url: string, params?: T): Promise<R>;
  delete<T, R>(url: string, params?: T): Promise<R>;
}

const client: Client = {
  post: async <T, R>(url: string, payload: T, config?: object): Promise<R> => {
    try {
      const response = await http.post<R>(url, payload, config);
      return response.data;
    } catch (error: unknown) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Não foi possível realizar a solicitação.");
      }
    }
  },

  put: async <T, R>(url: string, payload: T, config?: object): Promise<R> => {
    try {
      const response = await http.put<R>(url, payload, config);
      return response.data;
    } catch (error: unknown) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Não foi possível realizar a solicitação.");
      }
    }
  },

  get: async <T, R>(url: string): Promise<R> => {
    try {
      const response = await http.get<R>(url);
      return response.data;
    } catch (error: unknown) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Não foi possível realizar a solicitação.");
      }
    }
  },

  delete: async <T, R>(url: string, params?: T): Promise<R> => {
    try {
      const response = await http.delete<R>(url, { params });
      return response.data;
    } catch (error: unknown) {
      if (
        error instanceof AxiosError &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error("Não foi possível realizar a solicitação.");
      }
    }
  },
};

export default client;
