import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import React, { useMemo, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import useAlert from "../../Hooks/useAlert";
import useObterPedidoPorMatricula from "../../Hooks/useObterPedidoPorMatricula";
import { TipoDeServicos, TipoDeSolicitacao } from "../../Interface/IFormulario";
import { LoadingProps } from "../../Interface/ILoading";
import { limparFiltro, setItemFiltro } from "../../redux/pedido/filtroPedido";
import { postFiltrar } from "../../Repository/domain/Pedidos";
import {
    estados,
    etapaSolicitacao,
    responsavelPelaEtapa
} from "../../Utils/Filtros/indexTeste";
import { FormatarDataBrasileira } from "../../Utils/FormatarData";
import "./style.scss";

interface FiltroDeBuscaProps extends LoadingProps {
    onFiltrarPedidos: (dadosFiltrados: any) => void;
    tipoDeSolicitacao: TipoDeSolicitacao[]
    tipoDeServicos: TipoDeServicos[]
    codigoUsuario: string
}

const FiltroDeBuscaTeste: React.FC<FiltroDeBuscaProps> = ({
    setLoading,
    onFiltrarPedidos,
    tipoDeSolicitacao,
    tipoDeServicos,
    codigoUsuario
}) => {
    const { showAlertMessage } = useAlert();
    const dispatch = useDispatch();
    const filtroPedido = useSelector((state: any) => state.filtrarpedidoSlice);
    const obrasPorMatricula = useObterPedidoPorMatricula(codigoUsuario, () => { });
    const [inputValue, setInputValue] = useState("");


    const handleFiltrarClick = async () => {
        const filtroPedidoFormatado = {
            ...filtroPedido,
            codigoUsuario: codigoUsuario,
            dataPedido: filtroPedido.dataPedido ? FormatarDataBrasileira(filtroPedido?.dataPedido) : null,
            dataAssinaturaContrato: filtroPedido.dataAssinaturaContrato ? FormatarDataBrasileira(filtroPedido?.dataAssinaturaContrato) : null,
        };
        try {
            setLoading(true);
            const res = await postFiltrar(filtroPedidoFormatado);
            onFiltrarPedidos(res);
        } catch (error) {
            showAlertMessage("Erro ao filtrar dados:" + error, "warning");
        } finally {
            setLoading(false);
        }
    };

    const obras = useMemo(() => (
        obrasPorMatricula?.map((obra: any) => ({
            codigoObra: obra.codigoObra,
            key: `${obra.codigoObra}`,
            nomeObra: `${obra.nomeObra}`
        })) ?? []
    ).filter((obra: { codigoObra: any; }, index: any, self: any[]) =>
        index === self.findIndex((o) => o.codigoObra === obra.codigoObra)
    ), [obrasPorMatricula]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setItemFiltro({ ...filtroPedido, [name]: value }));
    };

    const handleSelectChangeEmergencial = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target;
        if (name) {
            dispatch(setItemFiltro({ ...filtroPedido, [name]: value === 1 ? true : false }));
        }
    };

    const handleSelectChangeStatus = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target;
        if (name) {
            dispatch(setItemFiltro({ ...filtroPedido, [name]: value }));
        }
    };

    return (
        <div className="d-flex flex-column gap-3" style={{ fontSize: "15px" }}>
            <div className="d-flex gap-3">
                <TextField
                    type="number"
                    className="autoComplete"
                    name="numeroPedido"
                    value={filtroPedido?.numeroPedido}
                    onChange={handleInputChange}
                    label="Número da solicitação"
                    slotProps={{ inputLabel: { shrink: false, } }}
                />
                <Autocomplete
                    className="autoComplete"
                    id="Obra"
                    options={
                        inputValue.length >= 3
                            ? obras.filter((obra: { codigoObra: string; }) =>
                                obra.codigoObra.toLowerCase().includes(inputValue.toLowerCase())
                            )
                            : []
                    }
                    value={obras.find((option: { codigoObra: any; }) => option.codigoObra === filtroPedido?.codigoObra) || null}
                    getOptionLabel={(option) => `${option.codigoObra} - ${option.nomeObra}` || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = { ...filtroPedido, codigoObra: newValue.codigoObra };
                            dispatch(setItemFiltro(mappedValue));
                        }
                    }}
                    inputValue={inputValue}
                    onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
                    isOptionEqualToValue={(option, value) => option.codigoObra === value.codigoObra}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label="Obra"
                            slotProps={{ inputLabel: { shrink: false } }}
                        />
                    )}
                    popupIcon={null}
                    noOptionsText="Nenhuma opção"
                />
                <TextField
                    className="autoComplete"
                    name="codigoEmpresa"
                    value={filtroPedido?.codigoEmpresa || ""}
                    onChange={handleInputChange}
                    label="Empresa"
                    slotProps={{ inputLabel: { shrink: false, } }}
                />
                <Autocomplete
                    className="autoComplete"
                    options={estados}
                    value={estados.find((option: { sigla: any; }) => option.sigla === filtroPedido?.estado) || null}
                    isOptionEqualToValue={(option: any, value) => option.sigla === value.estado}
                    getOptionLabel={(option) => option.descricao || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = { ...filtroPedido, estado: newValue.sigla };
                            dispatch(setItemFiltro(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label="Estado"
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                    />}
                    noOptionsText="Nenhuma opção"
                />
            </div>
            <div className="d-flex gap-3">
                <TextField
                    type="date"
                    className="autoComplete"
                    name="dataPedido"
                    value={filtroPedido?.dataPedido || ""}
                    onChange={handleInputChange}
                    label="Data da solicitação"
                    slotProps={{ inputLabel: { shrink: false, } }}
                />
                <Autocomplete
                    className="autoComplete"
                    options={tipoDeSolicitacao}
                    value={tipoDeSolicitacao.find((option: { tipoSolicitacao: any; }) => option.tipoSolicitacao === filtroPedido?.tipoSolicitacao) || null}
                    isOptionEqualToValue={(option: any, value) => option.tipoSolicitacao === value.tipoSolicitacao}
                    getOptionLabel={(option) => option.tipoSolicitacao || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = { ...filtroPedido, tipoSolicitacao: newValue.tipoSolicitacao };
                            dispatch(setItemFiltro(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label="Tipo de solicitação"
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                    />}
                    noOptionsText="Nenhuma opção"
                />
                <Autocomplete
                    className="autoComplete"
                    options={tipoDeServicos}
                    value={tipoDeServicos.find((option: { nomeServico: any; }) => option.nomeServico === filtroPedido?.tipoServico) || null}
                    isOptionEqualToValue={(option: any, value) => option.nomeServico === value.tipoServico}
                    getOptionLabel={(option) => option.nomeServico || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = { ...filtroPedido, tipoServico: newValue.nomeServico };
                            dispatch(setItemFiltro(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label="Tipo de serviço"
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                    />}
                    noOptionsText="Nenhuma opção"
                />
                <TextField
                    type="date"
                    className="autoComplete"
                    name="dataAssinaturaContrato"
                    value={filtroPedido?.dataAssinaturaContrato || ""}
                    onChange={handleInputChange}
                    label="Data assinatura contrato"
                    slotProps={{ inputLabel: { shrink: false, } }}
                />
            </div>
            <div className="d-flex gap-3">
                <FormControl className="autoComplete" fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                        displayEmpty
                        IconComponent={() => (
                            <IoIosArrowDown style={{ fontSize: '25px', marginRight: '15px', marginBottom: '20px', color: 'grey', cursor: 'pointer' }} />
                        )}
                        name='status'
                        onChange={handleSelectChangeStatus}
                        value={filtroPedido?.status === null ? 0 : filtroPedido?.status}
                    >
                        <MenuItem value={0}>Selecione...</MenuItem>
                        <MenuItem value={1}>Em andamento</MenuItem>
                        <MenuItem value={2}>Concluído</MenuItem>
                        <MenuItem value={3}>Cancelado</MenuItem>
                    </Select>
                </FormControl>
                <Autocomplete
                    className="autoComplete"
                    options={etapaSolicitacao}
                    value={etapaSolicitacao.find((option: { id: any; }) => option.id === filtroPedido?.etapa) || null}
                    isOptionEqualToValue={(option: any, value) => option.id === value.etapa}
                    getOptionLabel={(option) => option.descricao || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = { ...filtroPedido, etapa: newValue.id };
                            dispatch(setItemFiltro(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label="Etapa"
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                    />}
                    noOptionsText="Nenhuma opção"
                />
                <FormControl className="autoComplete" fullWidth>
                    <InputLabel>Emergencial</InputLabel>
                    <Select
                        displayEmpty
                        IconComponent={() => (
                            <IoIosArrowDown style={{ fontSize: '25px', marginRight: '15px', marginBottom: '20px', color: 'grey', cursor: 'pointer' }} />
                        )}
                        name='emergencial'
                        onChange={handleSelectChangeEmergencial}
                        value={filtroPedido?.emergencial === null ? 0 : filtroPedido?.emergencial === true ? 1 : 2}
                    >
                        <MenuItem value={0}>Selecione...</MenuItem>
                        <MenuItem value={1}>Sim</MenuItem>
                        <MenuItem value={2}>Não</MenuItem>
                    </Select>
                </FormControl>
                <Autocomplete
                    className="autoComplete"
                    options={responsavelPelaEtapa}
                    value={responsavelPelaEtapa.find((option: { responsavel: any; }) => option.responsavel === filtroPedido?.responsavelEtapa) || null}
                    isOptionEqualToValue={(option: any, value) => option.responsavel === value.responsavelEtapa}
                    getOptionLabel={(option) => option.responsavel || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = { ...filtroPedido, responsavelEtapa: newValue.responsavel };
                            dispatch(setItemFiltro(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label="Responsável pela etapa"
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                    />}
                    noOptionsText="Nenhuma opção"
                />
            </div>
            <div className="d-flex gap-3 justify-content-end">
                <button
                    className="btn btn-outline-primary"
                    onClick={handleFiltrarClick}
                >
                    Buscar
                </button>
                <button className="btn btn-outline-danger" onClick={() => { dispatch(limparFiltro()) }}>
                    Limpar
                </button>
            </div>
        </div>
    );
};

export default FiltroDeBuscaTeste;
