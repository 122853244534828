import { Autocomplete, TextField } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrcamentoSelecionado } from "../../redux/pedido/sliceViewModelEntrada";
import "./buscarCampo.scss";

interface BuscarCampoTesteProps {
    composicoes: { codigo: string; descricao: string }[];
}

const BuscarCampoTeste: React.FC<BuscarCampoTesteProps> = ({ composicoes }) => {
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = useState<any>(null);
    const dispatch = useDispatch();
    const orcamentosSelecionados = useSelector((state: any) => state.pedidoSliceViewModelEntrada.orcamentos.orcamentoItens);
    const mode = useSelector((state: any) => state.modeSlice.mode);

    const handleOptionSelectOrcamento = (item: any) => {
        if (mode === 'create') {
            dispatch(setOrcamentoSelecionado({
                codigoOrcamento: item.codigo,
                itemOrcamento: item.descricao,
            }));
            setInputValue("");
            setSelectedValue(null);
        } else {
            dispatch(setOrcamentoSelecionado({
                codigoOrcamento: item.codigo,
                itemOrcamento: `*${item.descricao}`,
            }));
            setInputValue("");
            setSelectedValue(null);
        }
    };

    return (
        <Autocomplete
            className="autoCompleteOrcamento"
            options={
                inputValue.length >= 3
                    ? composicoes.filter((composicoes: { codigo: string; descricao: string }) =>
                        (composicoes.codigo.toLowerCase().includes(inputValue.toLowerCase()) ||
                            composicoes.descricao.toLowerCase().includes(inputValue.toLowerCase())) &&
                        (composicoes.codigo !== orcamentosSelecionados.find((o: any) => o.codigoOrcamento === composicoes.codigo)?.codigoOrcamento)
                    )
                    : []
            }
            getOptionLabel={(option: any) => typeof option === 'string' ? option : `${option.codigo} - ${option.descricao}`}
            inputValue={inputValue}
            value={selectedValue}
            onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
            onChange={(_event, newValue) => {
                if (newValue) {
                    handleOptionSelectOrcamento(newValue);
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder="Código do item do orçamento..."
                />
            )}
            popupIcon={null}
            noOptionsText="Nenhuma opção encontrada"
        />
    );
};

export default BuscarCampoTeste;