import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import useDataAssinatura from "../../Hooks/useDataAssinatura";
import { setItem } from "../../redux/pedido/sliceViewModelEntrada";
import AlertaBootstrap from "../Alertas";

const DataAssinaturaTeste: React.FC = () => {
    const { justificativaEmergencial,
        validarDataDeAssinatura,
        showAlert,
        alertMessage,
        alertType,
        validarPrazoInicial,
        validarPrazoFinal, setDataDeAssinatura,
        setPrazoParaExecucaoInicial,
        setPrazoParaExecucaoFinal,
        pedidoEmergencial } = useDataAssinatura(null);

    const dataAtual = new Date().toISOString().split("T")[0];
    const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const dispatch = useDispatch();

    useEffect(() => {
        if (mode === "create") {
            dispatch(setItem({ ...novoPedido, flagEmergencial: pedidoEmergencial }));
        }
    }, [pedidoEmergencial]);

    useEffect(() => {
        if (novoPedido && mode === "create") {
            setDataDeAssinatura(novoPedido?.dataAssinatura);
            setPrazoParaExecucaoInicial(novoPedido?.dataInicioExecucao);
            setPrazoParaExecucaoFinal(novoPedido?.dataFimExecucao);
        }
    }, [novoPedido.dataAssinatura, novoPedido.dataInicioExecucao, novoPedido.dataFimExecucao, setDataDeAssinatura, setPrazoParaExecucaoInicial, setPrazoParaExecucaoFinal]);

    return (
        <div className="formulario">
            <div className="row justify-content-between">
                <div className="col-sm-12 col-md-6 mt-5">
                    <div className="row gap-1">
                        <div className="col-6">
                            {showAlert && (
                                <AlertaBootstrap mensagem={alertMessage} tipo={alertType} />
                            )}
                            <div className="form-floating mb-3">
                                <TextField
                                    disabled={mode === 'view'}
                                    value={novoPedido.dataAssinatura}
                                    type="date"
                                    className="inputForm"
                                    name="dataAssinatura"
                                    slotProps={{
                                        htmlInput: { min: dataAtual },
                                        inputLabel: { shrink: false }
                                    }}
                                    label={<>Data de assinatura do contrato: <span style={{ color: 'red' }}>*</span></>}
                                    helperText={pedidoEmergencial ? <span style={{ color: 'red', fontWeight: 'bold', fontSize: '13px' }}>Pedido Emergencial</span> : ''}
                                    onChange={(e) => {
                                        if (validarDataDeAssinatura(e.target.value)) {
                                            dispatch(setItem({ ...novoPedido, dataAssinatura: e.target.value }));
                                        }
                                    }}
                                />
                            </div>
                            {novoPedido.descricaoTipoSolicitacao === "Contratação Inicial" && (
                                <div className="form-floating mb-3">
                                    <TextField
                                        value={novoPedido?.prazoContratacao}
                                        type="date"
                                        disabled
                                        className="inputForm"
                                        name="dataAssinatura"
                                        slotProps={{
                                            htmlInput: { min: dataAtual },
                                            inputLabel: { shrink: false }
                                        }}
                                        label="Data de assinatura do contrato - BI:"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <p className="mb-4 text-primary">
                        Prazo para a execução dos serviços:
                    </p>
                    <div className="row gap-1">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <TextField
                                    disabled={mode === 'view'}
                                    value={novoPedido.dataInicioExecucao}
                                    type="date"
                                    className="inputForm"
                                    name="dataInicioExecucao"
                                    label={<> Prazo para execução do serviço (início):<span style={{ color: 'red' }}>*</span></>}
                                    slotProps={{
                                        htmlInput: { min: novoPedido.dataAssinatura },
                                        inputLabel: { shrink: false }
                                    }}
                                    onChange={(e) => {
                                        if (validarPrazoInicial(e.target.value)) {
                                            dispatch(setItem({ ...novoPedido, dataInicioExecucao: e.target.value }));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row gap-1">
                        <div className="col-6">
                            <div className="form-floating mb-3">
                                <TextField
                                    disabled={mode === 'view'}
                                    value={novoPedido.dataFimExecucao}
                                    type="date"
                                    className="inputForm"
                                    name="dataFimExecucao"
                                    label={<> Prazo para execução do serviço (término):<span style={{ color: 'red' }}>*</span></>}
                                    slotProps={{
                                        htmlInput: { min: novoPedido.dataInicioExecucao },
                                        inputLabel: { shrink: false }
                                    }}
                                    onChange={(e) => {
                                        if (validarPrazoFinal(e.target.value)) {
                                            dispatch(setItem({ ...novoPedido, dataFimExecucao: e.target.value }));
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {pedidoEmergencial && (
                    <div className="form-floating w-100">
                        <FormControl className="inputForm" fullWidth>
                            <InputLabel>
                                <> Justificativa<span style={{ color: 'red' }}>*</span></>
                            </InputLabel>
                            <Select
                                disabled={mode === 'view'}
                                MenuProps={{ style: { maxHeight: 300 } }}
                                IconComponent={() => (
                                    <IoIosArrowDown style={{ fontSize: '22px', marginRight: '15px', marginBottom: '20px', color: 'grey', cursor: 'pointer' }} />
                                )}
                                onChange={(e) => {
                                    dispatch(setItem({ ...novoPedido, justificativaEmergencial: e.target.value }));
                                }}
                                value={novoPedido.justificativaEmergencial || ""}
                            >
                                {justificativaEmergencial
                                    .sort((a: any, b: any) =>
                                        a.descricao.localeCompare(b.descricao)
                                    )
                                    .map((justificativa: any) => (
                                        <MenuItem
                                            key={justificativa.id}
                                            value={justificativa.descricao}
                                        >
                                            {justificativa.descricao}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                )}
            </div>
        </div >
    );
};

export default DataAssinaturaTeste;
