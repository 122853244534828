import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDetalhePedidoViewModelSaida } from '../Interfaces/IPedidoViewModelSaida';

// interface IDetalhePedidoViewModelSaida {
//     pedido: {
//         codigoPedido: number;
//         idEmpresaObra: number;
//         codigoEmpresa: number;
//         razaoSocial: string;
//         cnpj: string | null;
//         codigoObra: string;
//         nomeEmpreendimento: string;
//         tipoSolicitacao: string;
//         tipoServico: number;
//         descricaoServico: string;
//         tipoAditivo: number;
//         estado: string;
//         fornecedor: string;
//         contratoUAU: string;
//         distrato: boolean;
//         saldoMedicaoPagar: string;
//         valorPagar: number;
//         descricaoAditivo: string | null;
//         processoAnterior: number;
//         dataAssinatura: string;
//         flagEmergencial: boolean;
//         dataInicioExecucao: string;
//         dataFimExecucao: string;
//         relacaoProjetos: string;
//         especificacaoMateriais: string;
//         nbrs: string;
//         obrigacoesFornecedores: string;
//         fornecedoresIndicados: string;
//         condicoesPagamentos: string;
//         criterioMedicao: string;
//         detalhamento: string;
//         dataPedido: string;
//         justificativaEmergencial: string;
//         justificativaReabertura: string | null;
//         justificativaPedido: string;
//         prazoContratacao: string;
//         justificativaAditivo: string | null;
//         status: string;
//         quantitativos: Array<{
//             codigoComposicao: string;
//             descricaoComposicao: string;
//             unidade: string;
//             quantidadeSolicitada: number;
//             quantidadeUau: number | null;
//             desvio: number | null;
//             observacoes: string;
//             material: boolean;
//             codigoPLM: string;
//             categoriaUau: string;
//         }>;
//         fornecedores: any;
//     };
//     cronograma: any;
//     orcamento: {
//         valorTotalOrcamento: number;
//         itemSemOrcamento: boolean;
//         justificativa: string;
//         orcamentoItens: Array<{
//             codigoOrcamento: string;
//             itemOrcamento: string;
//             unidadeOrcada: string;
//             quantidadeOrcada: number;
//             valorUnitario: number;
//             valorOrcamento: number;
//             valorAjustado: number;
//             observacoes: string;
//         }>;
//     };
// }

const initialState = {
    // pedido: {
    //     codigoPedido: null,
    //     idEmpresaObra: null,
    //     codigoEmpresa: null,
    //     razaoSocial: null,
    //     cnpj: null,
    //     codigoObra: null,
    //     nomeEmpreendimento: null,
    //     tipoSolicitacao: null,
    //     tipoServico: null,
    //     descricaoServico: null,
    //     tipoAditivo: null,
    //     estado: null,
    //     fornecedor: null,
    //     contratoUAU: null,
    //     distrato: null,
    //     saldoMedicaoPagar: null,
    //     valorPagar: null,
    //     descricaoAditivo: null,
    //     processoAnterior: null,
    //     dataAssinatura: null,
    //     flagEmergencial: null,
    //     dataInicioExecucao: null,
    //     dataFimExecucao: null,
    //     relacaoProjetos: null,
    //     especificacaoMateriais: null,
    //     nbrs: null,
    //     obrigacoesFornecedores: null,
    //     fornecedoresIndicados: null,
    //     condicoesPagamentos: null,
    //     criterioMedicao: null,
    //     detalhamento: null,
    //     dataPedido: null,
    //     justificativaEmergencial: null,
    //     justificativaReabertura: null,
    //     justificativaPedido: null,
    //     prazoContratacao: null,
    //     justificativaAditivo: null,
    //     status: null,
    //     quantitativos: null,
    //     fornecedores: null,
    // },
    // cronograma: null,
    // orcamento: {
    //     valorTotalOrcamento: null,
    //     itemSemOrcamento: null,
    //     justificativa: null,
    //     orcamentoItens: null,
    // },
};

const mapSaidaToEntrada = (saida: IDetalhePedidoViewModelSaida) => ({
    cnpj: saida.pedido?.cnpj ?? null,
    codigoEmpresa: saida.pedido?.codigoEmpresa ?? null,
    codigoObra: saida.pedido?.codigoObra ?? null,
    codigoPedido: saida.pedido?.codigoPedido ?? null,
    condicoesPagamentos: saida.pedido?.condicoesPagamentos ?? null,
    contratoUAU: saida.pedido?.contratoUAU ?? null,
    criterioMedicao: saida.pedido?.criterioMedicao ?? null,
    dataAssinatura: saida.pedido?.dataAssinatura ?? null,
    dataFimExecucao: saida.pedido?.dataFimExecucao ?? null,
    dataInicioExecucao: saida.pedido?.dataInicioExecucao ?? null,
    descricaoAditivo: saida.pedido?.descricaoAditivo ?? null,
    descricaoServico: saida.pedido?.descricaoServico ?? null,
    descricaoTipoSolicitacao: saida.pedido?.tipoSolicitacao ?? null,
    detalhamento: saida.pedido?.detalhamento ?? null,
    distrato: saida.pedido?.distrato ?? null,
    especificacaoMateriais: saida.pedido?.especificacaoMateriais ?? null,
    estado: saida.pedido?.estado ?? null,
    flagEmergencial: saida.pedido?.flagEmergencial ?? null,
    fornecedor: saida.pedido?.fornecedor ?? null,
    fornecedoresIndicados: saida.pedido?.fornecedoresIndicados ?? null,
    fornecedores: saida.pedido?.fornecedores ?? null,
    idEmpresaObra: saida.pedido?.idEmpresaObra ?? null,
    idServico: saida.pedido?.tipoServico ?? null,
    justificativaEmergencial: saida.pedido?.justificativaEmergencial ?? null,
    justificativaPedido: saida.pedido?.justificativaPedido ?? null,
    justificativaReabertura: saida.pedido?.justificativaReabertura ?? null,
    nbrs: saida.pedido?.nbrs ?? null,
    nomeEmpreendimento: saida.pedido?.nomeEmpreendimento ?? null,
    obrigacoesFornecedores: saida.pedido?.obrigacoesFornecedores ?? null,
    orcamentos: saida.orcamento ?? null,
    prazoContratacao: saida.pedido?.prazoContratacao ?? null,
    processoAnterior: saida.pedido?.processoAnterior ?? null,
    quantitativos: saida.pedido?.quantitativos ?? null,
    razaoSocial: saida.pedido?.razaoSocial ?? null,
    relacaoProjetos: saida.pedido?.relacaoProjetos ?? null,
    saldoMedicaoPagar: saida.pedido?.saldoMedicaoPagar ?? null,
    tipoAditivo: saida.pedido?.tipoAditivo ?? null,
    tipoServico: saida.pedido?.descricaoServico ?? null,
    valorPagar: saida.pedido?.valorPagar ?? null,
    dataPedido: saida.pedido?.dataPedido ?? null
});

const pedidoSliceViewModelSaida = createSlice({
    name: 'pedidoViewModelSaida',
    initialState,
    reducers: {
        setDetalhePedido(state, action: PayloadAction<IDetalhePedidoViewModelSaida>) {
            const entrada = mapSaidaToEntrada(action.payload);
            return { ...state, ...entrada };
        },
    },
});

export const { setDetalhePedido } = pedidoSliceViewModelSaida.actions;
export default pedidoSliceViewModelSaida.reducer;