import { extrairGruposDoTokenGA } from "../../Utils/TokensUtils";

export const verificaPermissaoUsuario = (
  tokenGA: string,
  etapaStatus?: string,
  responsavelPelaEtapa?: string
): boolean => {
  const grupos = extrairGruposDoTokenGA(tokenGA);

  const etapaStatusNormalizada = etapaStatus ? etapaStatus : "";
  const responsavelPelaEtapaNormalizada = responsavelPelaEtapa
    ? responsavelPelaEtapa.toUpperCase().replace(/\s+/g, "")
    : "";

  return grupos.some(
    (grupo) =>
      grupo === responsavelPelaEtapaNormalizada &&
      etapaStatusNormalizada === "Cotação da solicitação do serviço"
  );
};
