import axios, { AxiosInstance, AxiosResponse } from "axios";
import { AutenticacaoService } from "../../Services/AutenticacaoService";

const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ADDRESS,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use((config) => {
  let tokenCognito = localStorage.getItem("tokenCognito");
  let tokenAcesso = localStorage.getItem("accessToken");
  let tokenGA = localStorage.getItem("tokenGA");

  if (tokenCognito && tokenAcesso && tokenGA) {
    config.headers["Authorization"] = `Bearer ${tokenCognito}`;
    config.headers["AccessToken"] = tokenAcesso;
    config.headers["GestaoAcessoToken"] = tokenGA;
  }

  return config;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  function (error) {
    if (error.response.status === 401 /*|| error.response.status === 400*/) {
      new AutenticacaoService().realizarLogout();
    }

    return Promise.reject(error);
  }
);

export default instance;
