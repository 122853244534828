import { combineReducers } from "redux";
import filtrarpedidoSlice from "./pedido/filtroPedido";
import modeSlice from "./pedido/slice";
import pedidoSliceViewModelEntrada from "./pedido/sliceViewModelEntrada";
import pedidoSliceViewModelSaida from "./pedido/sliceViewModelSaida";

const rootReducer = combineReducers({
    modeSlice,
    pedidoSliceViewModelSaida,
    pedidoSliceViewModelEntrada,
    filtrarpedidoSlice
});

export default rootReducer;