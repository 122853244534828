import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFornecedorViewModelEntrada, IOrcamentoItemViewModelEntrada, IOrcamentoViewModelEntrada, IPedidoViewModelEntrada, IQuantitativosViewModelEntrada } from '../Interfaces/IPedidoViewModelEntrada';
import { IDetalhePedidoViewModelSaida } from '../Interfaces/IPedidoViewModelSaida';
interface UpdateOrcamentoPayload {
    id: string;
    field: string;
    value: any;
}
const initialState: IPedidoViewModelEntrada = {
    cnpj: '',
    codigoEmpresa: 0,
    codigoObra: '',
    codigoObraFiscal: '',
    codigoPedido: 0,
    codigoServicoBI: '',
    codigoUsuario: '',
    condicoesPagamentos: '',
    contratoUAU: '',
    criterioMedicao: '',
    dataAssinatura: '',
    dataFimExecucao: '',
    dataInicioExecucao: '',
    descricaoAditivo: null,
    descricaoServico: '',
    descricaoTipoSolicitacao: '',
    detalhamento: '',
    distrato: false,
    especificacaoMateriais: '',
    estado: '',
    flagEmergencial: false,
    fornecedor: '',
    fornecedores: [],
    fornecedoresIndicados: '',
    idEmpresaObra: 0,
    idServico: 0,
    justificativaEmergencial: null,
    justificativaPedido: '',
    justificativaReabertura: null,
    nbrs: '',
    nomeEmpreendimento: '',
    obrigacoesFornecedores: '',
    orcamentos: {
        itemSemOrcamento: false,
        justificativa: '',
        valorTotalOrcamento: 0,
        orcamentoItens: [],
    },
    prazoContratacao: '',
    processoAnterior: 0,
    quantitativos: [],
    razaoSocial: '',
    relacaoProjetos: '',
    saldoMedicaoPagar: '',
    tipoAditivo: null,
    tipoServico: '',
    tipoSolicitacao: 0,
    valorPagar: 0,
    dataPedido: ''
};

const pedidoSliceViewModelEntrada = createSlice({
    name: 'pedidoViewModelEntrada',
    initialState,
    reducers: {
        setItem(state, action: PayloadAction<IPedidoViewModelEntrada>) {
            return { ...state, ...action.payload };
        },
        setOrcamentos(state, action: PayloadAction<IOrcamentoViewModelEntrada>) {
            state.orcamentos = { ...state.orcamentos, ...action.payload };
        },
        setOrcamentoSelecionado: (state, action: PayloadAction<IOrcamentoItemViewModelEntrada>) => {
            state.orcamentos.orcamentoItens.push(action.payload);
        },
        updateOrcamento: (state, action: PayloadAction<UpdateOrcamentoPayload>) => {
            const { id, field, value } = action.payload;
            const index = state.orcamentos.orcamentoItens.findIndex(
                (orcamento) => orcamento.codigoOrcamento === id
            );
            if (index !== -1) {
                state.orcamentos.orcamentoItens[index] = {
                    ...state.orcamentos.orcamentoItens[index],
                    [field]: value,
                };
            }
        },
        setQuantitativosSelecionados: (state, action: PayloadAction<IQuantitativosViewModelEntrada>) => {
            state.quantitativos.push(action.payload);
        },
        updateQuantitativos: (state, action: PayloadAction<UpdateOrcamentoPayload>) => {
            const { id, field, value } = action.payload;
            const index = state.quantitativos.findIndex(
                (quantitativo) => quantitativo.codigoComposicao === id
            );
            if (index !== -1) {
                state.quantitativos[index] = {
                    ...state.quantitativos[index],
                    [field]: value,
                };
            }
        },
        removeOrcamentoSelecionado: (state, action: PayloadAction<string>) => {
            state.orcamentos.orcamentoItens = state.orcamentos.orcamentoItens.filter(
                (orcamento) => orcamento.codigoOrcamento !== action.payload
            );
        },
        removeQuantitativoSelecionado: (state, action: PayloadAction<string>) => {
            state.quantitativos = state.quantitativos.filter(
                (quantitativo) => quantitativo.codigoComposicao !== action.payload
            );
        },
        setPedidoExistente(state, action: PayloadAction<IDetalhePedidoViewModelSaida>) {
            return { ...state, ...action.payload };
        },
        setDadosContratacao(state, action: PayloadAction<IFornecedorViewModelEntrada>) {
            state.fornecedores = { ...state.fornecedores, ...action.payload };
        }
    },
});

export const { setItem,
    setOrcamentos,
    updateOrcamento,
    setOrcamentoSelecionado,
    setQuantitativosSelecionados,
    updateQuantitativos,
    removeOrcamentoSelecionado,
    removeQuantitativoSelecionado,
    setPedidoExistente,
    setDadosContratacao } = pedidoSliceViewModelEntrada.actions;

export default pedidoSliceViewModelEntrada.reducer;