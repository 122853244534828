import { Pagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { DataGrid, GridColDef, GridPaginationModel } from '@mui/x-data-grid';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { TabelaPaginadaProps } from '../../Interface/ITabelaPaginada';
import "./style.scss";

const TabelaPaginadaTeste: React.FC<TabelaPaginadaProps> = ({ dados,
    itensPorPagina }) => {
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 30 });

    const rows = (dados && Array.isArray(dados))
        ? dados.map((dado) => ({
            id: dado.numeroPedido,
            solicitacao: dado.numeroPedido,
            estado: dado.estado,
            empresa: dado.empresa,
            obra: dado.codigoObra,
            tipoSolicitacao: dado.tipoSolicitacao,
            tipoServico: dado.tipoServico,
            dataSolicitacao: dado.dataPedido,
            emergencial: dado.emergencial ? "Sim" : "Não",
            dataAssContratual: dado.dataAssinaturaContrato,
            prazoAssContratual: dado.prazoAssinaturaContrato,
            etapa: dado.etapa,
            responsavel: dado.responsavelEtapa,
            acao: dado.acao,
            etapaStatus: dado.etapaStatus
        })) : [];

    const columns: GridColDef[] = [
        { field: 'solicitacao', headerName: 'Solicitação', flex: 0.8 },
        { field: 'estado', headerName: 'Estado', flex: 0.6 },
        { field: 'empresa', headerName: 'Empresa', flex: 0.6 },
        { field: 'obra', headerName: 'Obra', flex: 0.5 },
        { field: 'tipoSolicitacao', headerName: 'Tipo solicitação', flex: 1.3 },
        { field: 'tipoServico', headerName: 'Tipo serviço', flex: 2.6 },
        { field: 'dataSolicitacao', headerName: 'Data solicitação', flex: 0.9 },
        { field: 'emergencial', headerName: 'Emergencial', flex: 0.9 },
        { field: 'dataAssContratual', headerName: 'Data ass. contr.', flex: 0.9 },
        { field: 'prazoAssContratual', headerName: 'Prazo p/ ass. contr.', flex: 0.5 },
        { field: 'etapa', headerName: 'Etapa', flex: 1.2 },
        { field: 'responsavel', headerName: 'Responsável', flex: 1 },
        {
            field: 'acao',
            headerName: 'Ação',
            flex: 1.2,
            renderCell: (params) => (
                <Link
                    to={`/aprovacoes/${params.id}`}
                    className="btn btn-outline-primary"
                    state={{
                        etapa: params.row.etapaStatus,
                        responsavelPelaEtapa: params.row.responsavel,
                        acao: params.row.acao,
                    }}
                >
                    {params.row.acao ? 'Executar' : 'Visualizar'}
                </Link>
            )
        }

    ]

    return (
        <Paper sx={{ width: '100%' }} elevation={0}>
            <DataGrid
                disableColumnResize={true}
                className='tabelaPaginada'
                rows={rows}
                columns={columns}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                disableColumnMenu
                columnHeaderHeight={80}
                hideFooterSelectedRowCount
                disableVirtualization
                rowHeight={65}
                slots={{
                    pagination: () => (
                        <CustomPagination
                            paginationModel={paginationModel}
                            setPaginationModel={setPaginationModel}
                            rowCount={rows.length}
                        />
                    ),
                    noRowsOverlay: NoRowsOverlay
                }}
            />
        </Paper>
    );
}

export default TabelaPaginadaTeste;


interface CustomPaginationProps {
    paginationModel: GridPaginationModel;
    setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>;
    rowCount: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({ paginationModel, setPaginationModel, rowCount }) => {
    const totalPages = Math.ceil(rowCount / paginationModel.pageSize);
    return (
        <Pagination
            count={totalPages}
            page={paginationModel.page + 1}
            onChange={(_event, value) => setPaginationModel(prev => ({ ...prev, page: value - 1 }))}
            showFirstButton
            showLastButton
        />
    );
};

const NoRowsOverlay = () => (
    <Typography
        variant="body1"
        color="textSecondary"
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            padding: 2,
        }}
    >
        Nenhum pedido encontrado.
    </Typography>
);