import { TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setItem } from "../../redux/pedido/sliceViewModelEntrada";
import "./detalhamentopedidoteste.scss";

const DetalhesPedidoTeste: React.FC = () => {
    const dispatch = useDispatch();
    const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const mode = useSelector((state: any) => state.modeSlice.mode);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setItem({ ...novoPedido, [name]: value }));
    };

    return (
        <div className="formulario">
            <TextField
                disabled={mode === 'view'}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="justificativaPedido"
                label={<>Justificativa <span style={{ color: 'red' }}>*</span></>}
                value={novoPedido.justificativaPedido}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.relacaoProjetos}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="relacaoProjetos"
                label={<>Relação de projetos a serem encaminhados aos prestadores de serviços <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.especificacaoMateriais}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="especificacaoMateriais"
                label={<>Especificação dos materiais a serem aplicados nos serviços <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.nbrs}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="nbrs"
                label={<>NBR's <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.obrigacoesFornecedores}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="obrigacoesFornecedores"
                label={<>Obrigações do fornecedor <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.fornecedoresIndicados}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="fornecedoresIndicados"
                label={<>Fornecedores indicados (nome, telefone e e-mail) <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.condicoesPagamentos}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="condicoesPagamentos"
                label={<>Condição de pagamento <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.criterioMedicao}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="criterioMedicao"
                label={<>Critério de medição <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
            <TextField
                disabled={mode === 'view'}
                value={novoPedido.detalhamento}
                multiline
                rows={2}
                slotProps={{ inputLabel: { shrink: false, } }}
                onChange={handleInputChange}
                className="inputDetalhamento"
                name="detalhamento"
                label={<>Detalhamento do serviço <span style={{ color: 'red' }}>*</span></>}
            ></TextField>
        </div>
    );
};

export default DetalhesPedidoTeste;
