import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FiltrarPedido } from "../../Interface/IFiltrarPedido";

const initialState: FiltrarPedido = {
    codigoUsuario: '',
    numeroPedido: 0,
    codigoObra: null,
    codigoEmpresa: null,
    status: null,
    dataPedido: null,
    idEmpresaObra: null,
    tipoSolicitacao: null,
    tipoServico: null,
    tipoAditivo: null,
    estado: null,
    emergencial: null,
    empresa: null,
    dataAssinaturaContrato: null,
    responsavelEtapa: null,
    etapa: null,
}


const filtrarpedidoSlice = createSlice({
    name: 'filtrarpedido',
    initialState,
    reducers: {
        setItemFiltro(state, action: PayloadAction<FiltrarPedido>) {
            return { ...state, ...action.payload };
        },
        limparFiltro() {
            return initialState;
        },
    },
});

export const { setItemFiltro, limparFiltro } = filtrarpedidoSlice.actions;

export default filtrarpedidoSlice.reducer;
