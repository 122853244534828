import { useState, useEffect, useCallback } from "react";
import { getObterDetalhesDoPedido } from "../Repository/domain/Pedidos";
import useAlert from "./useAlert";

const useDetalhesDoPedido = (numeroPedido: number) => {
  const [detalhesDoPedido, setDetalhesDoPedido] = useState<any>(null);
  const { showAlertMessage } = useAlert();

  const fetchDetalhesDoPedido = useCallback(
    async (numeroPedido: number) => {
      try {
        const detalhes = await getObterDetalhesDoPedido(numeroPedido);
        setDetalhesDoPedido(detalhes);
      } catch (error: any) {
        showAlertMessage(
          "Erro ao carregar os detalhes do pedido: " + error.message,
          "danger"
        );
      }
    },
    [showAlertMessage]
  );

  useEffect(() => {
    fetchDetalhesDoPedido(numeroPedido);
  }, [fetchDetalhesDoPedido, numeroPedido]);

  return detalhesDoPedido;
};

export default useDetalhesDoPedido;
