import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { LoadingProps } from "../Interface/ILoading";
import AcompanharPedido from "../Pages/AcompanharPedido";
import AprovarPedidoTeste from "../Pages/AprovarPedido/aprovarPedidoTeste";
import CriarPedidoTeste from "../Pages/CriarPedidoTeste";
import Login from "../Pages/Login";

const RoutesComponent: React.FC<LoadingProps> = ({ setLoading }) => {

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login/:tela/:tokenCognito/:tokenGA/:accessToken"
          element={<Login setLoading={setLoading} />}
        />
        <Route
          path="/solicitar/"
          element={<CriarPedidoTeste setLoading={setLoading} />}
        />
        <Route
          path="/pedidos/"
          element={<AcompanharPedido setLoading={setLoading} />}
        />
        <Route
          path="/visualizarPedido/:numeroPedido"
          element={<CriarPedidoTeste setLoading={setLoading} />}
        />
        <Route
          path="/aprovacoes/:numeroPedido"
          element={<AprovarPedidoTeste setLoading={setLoading} />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
