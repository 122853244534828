import { useState, useCallback } from "react";

const useLogin = () => {
  const [logado, setLogado] = useState(false);

  const alterarUsuarioLogado = useCallback((usuarioLogado: boolean) => {
    setLogado(usuarioLogado);
  }, []);

  return { logado, alterarUsuarioLogado };
};

export default useLogin;
