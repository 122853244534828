import { Autocomplete, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useFormData } from "../../Hooks/useFormData";
import useTipoDeSolicitacao from "../../Hooks/useTipoDeSolicitacao";
import { setItem } from "../../redux/pedido/sliceViewModelEntrada";
import { formatarMoedaBrasileira } from "../../Utils/FormatarMoedaBrasileira";
import "./formulario.scss";

interface FormularioTesteProps {
    servicos: any[];
    obrasPorMatricula: any
}
const FormularioTeste: React.FC<FormularioTesteProps> = ({ servicos, obrasPorMatricula }) => {
    const dispatch = useDispatch();
    const tipoDeSolicitacao = useTipoDeSolicitacao();
    const desabilitado = useSelector((state: any) => state.modeSlice.desabilitado);
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const [inputValue, setInputValue] = useState("");
    const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);

    const obras = useMemo(() => {
        if (!obrasPorMatricula) return [];
        const obrasFiltradas = obrasPorMatricula?.filter(
            (obra: { codigoObra: any; nomeObra: any; nomeEmpresa: any; codigoEmpresa: any }, index: any, self: any[]) =>
                index === self.findIndex(
                    (o) => o.codigoObra === obra.codigoObra
                        && o.nomeObra === obra.nomeObra
                        && o.nomeEmpresa === obra.nomeEmpresa
                        && o.codigoEmpresa === obra.codigoEmpresa
                )
        );
        return obrasFiltradas;
    }, [obrasPorMatricula]);

    const handleValorPagarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        const valorPagar = Number(value.replace(/[R$.,\s]/g, "")) / 100;
        dispatch(setItem({ ...novoPedido, [name]: valorPagar }));
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setItem({ ...novoPedido, [name]: value }));
    };

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target;
        if (name) {
            dispatch(setItem({ ...novoPedido, [name]: value === 1 ? true : false }));
        }
    };
    const { fornecedores } = useFormData(
        novoPedido?.codigoEmpresa.toString(),
        novoPedido?.codigoObra
    );

    return (
        <div className="formulario">
            <div className="d-flex gap-4">
                <TextField
                    className="inputForm"
                    label="Empresa"
                    name="codigoEmpresa"
                    disabled
                    value={novoPedido.codigoObra == '' ? '' :
                        `${novoPedido.codigoEmpresa} - ${novoPedido.razaoSocial}`
                    }
                />
                <Autocomplete
                    className="inputForm"
                    options={
                        inputValue.length >= 2
                            ? obras.filter((obra: { codigoObra: string; }) =>
                                obra.codigoObra.toLowerCase().includes(inputValue.toLowerCase())
                            )
                            : []
                    }
                    value={obras.find((option: { codigoObra: any; }) => option.codigoObra === novoPedido.codigoObra) || null}
                    getOptionLabel={(option) => option.codigoObra == '' ? '' : `${option.codigoObra} - ${option.nomeObra}`}
                    inputValue={inputValue}
                    onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
                    isOptionEqualToValue={(option, value) => option.codigoObra === value.codigoObra && option.codigoEmpresa === novoPedido.codigoEmpresa}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label={<>Obra <span style={{ color: 'red' }}>*</span></>}
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={null}
                    noOptionsText="Nenhuma opção"
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = {
                                ...newValue,
                                nomeEmpreendimento: newValue.nomeObra,
                                razaoSocial: newValue.nomeEmpresa,
                            };
                            dispatch(setItem(mappedValue));
                        }
                    }}
                    disabled={mode === "view"}
                />
            </div>
            <div className="d-flex gap-4">
                <Autocomplete
                    className="inputForm"
                    options={tipoDeSolicitacao}
                    value={tipoDeSolicitacao.find((option: { tipoSolicitacao: any; }) => option.tipoSolicitacao === novoPedido.descricaoTipoSolicitacao) || null}
                    isOptionEqualToValue={(option: any, value) => option.id === value.id}
                    getOptionLabel={(option) => option.tipoSolicitacao || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = {
                                ...novoPedido,
                                tipoSolicitacao: newValue.id,
                                descricaoTipoSolicitacao: newValue.tipoSolicitacao,
                            };
                            dispatch(setItem(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label={<>Tipo de solicitação <span style={{ color: 'red' }}>*</span></>}
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }} />}
                    noOptionsText="Nenhuma opção"
                    disabled={desabilitado}
                />
                <Autocomplete
                    className="inputForm"
                    options={servicos}
                    value={servicos.find((option: { id: any; }) => option.id === novoPedido.idServico) || null}
                    isOptionEqualToValue={(option: any, value) => option.id === value.id}
                    getOptionLabel={(option) => option.nomeServico || ""}
                    onChange={(_event, newValue) => {
                        if (newValue) {
                            const mappedValue = {
                                ...novoPedido,
                                idServico: newValue.id,
                                descricaoServico: newValue.nomeServico,
                                tipoServico: newValue.nomeServico,
                                codigoServicoBI: newValue.codigoServicoBI,
                            };
                            dispatch(setItem(mappedValue));
                        }
                    }}
                    renderInput={(params: any) => (
                        <TextField
                            {...params}
                            label={<>Tipo de serviço <span style={{ color: 'red' }}>*</span></>}
                            slotProps={{ inputLabel: { shrink: false, } }}
                        />
                    )}
                    popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                    />}
                    noOptionsText="Nenhuma opção"
                    disabled={desabilitado}
                />
                <TextField
                    className="inputForm"
                    label="Estado"
                    disabled
                    value={novoPedido?.estado}
                />
                <TextField
                    className="inputForm"
                    label="Data do pedido"
                    disabled
                    value={novoPedido?.dataPedido}
                    type="date"
                    slotProps={{ inputLabel: { shrink: false, } }}
                />
            </div>
            {novoPedido?.descricaoTipoSolicitacao !== "Contratação Inicial" &&
                novoPedido?.descricaoTipoSolicitacao !== "" &&
                novoPedido !== null && (
                    <>
                        < div className="d-flex gap-4">
                            <Autocomplete
                                className="inputForm"
                                id="Fornecedor"
                                options={fornecedores || []}
                                value={fornecedores.find((option: { nomeFornecedor: any; }) => option.nomeFornecedor === novoPedido.fornecedor) || null}
                                isOptionEqualToValue={(option: any, value) => option.codigoFornecedor === value.codigoFornecedor}
                                getOptionLabel={(option) => option.nomeFornecedor || ""}
                                renderInput={(params: any) => (
                                    <TextField
                                        {...params}
                                        label='Fornecedor'
                                        slotProps={{ inputLabel: { shrink: false, } }}
                                    />
                                )}
                                popupIcon={<IoIosArrowDown style={{ fontSize: '18px', marginRight: '5px' }}
                                />}
                                noOptionsText="Nenhuma opção"
                                onChange={(_event, newValue) => {
                                    if (newValue) {
                                        const mappedValue = {
                                            ...novoPedido,
                                            fornecedor: newValue.nomeFornecedor,
                                        };
                                        dispatch(setItem(mappedValue));
                                    }
                                }}
                                disabled={desabilitado}
                            />
                            <TextField
                                className="inputForm"
                                label="N° da contratação inicial"
                                type="number"
                                name="processoAnterior"
                                value={novoPedido?.processoAnterior}
                                onChange={handleInputChange}
                                slotProps={{ inputLabel: { shrink: false, } }}
                                disabled={desabilitado}
                            />
                            <TextField
                                className="inputForm"
                                label="N° do contrato UAU"
                                type="number"
                                name="contratoUAU"
                                value={novoPedido?.contratoUAU}
                                onChange={handleInputChange}
                                slotProps={{ inputLabel: { shrink: false, } }}
                                disabled={desabilitado}
                            />
                            <FormControl className="inputForm" fullWidth>
                                <InputLabel>Haverá distrato?</InputLabel>
                                <Select
                                    displayEmpty
                                    IconComponent={() => (
                                        <IoIosArrowDown style={{ fontSize: '25px', marginRight: '15px', marginBottom: '20px', color: 'grey' }} />
                                    )}
                                    name='distrato'
                                    onChange={handleSelectChange}
                                    value={novoPedido?.distrato === true ? 1 : 2}
                                    disabled={desabilitado}
                                >
                                    <MenuItem value={1}>Sim</MenuItem>
                                    <MenuItem value={2}>Não</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        {novoPedido?.distrato === true && (
                            <div className="w-25">
                                <TextField
                                    className="inputForm"
                                    label={<>Qual o valor? <span style={{ color: 'red' }}>*</span></>}
                                    value={formatarMoedaBrasileira(novoPedido.valorPagar)}
                                    slotProps={{ inputLabel: { shrink: false, } }}
                                    name='valorPagar'
                                    onChange={handleValorPagarChange}
                                    disabled={desabilitado}
                                />
                            </div>
                        )}
                    </>
                )
            }
            <hr />
        </div >
    )
}

export default FormularioTeste;