import { useState, useEffect, useCallback } from "react";
import { getPrazoBI } from "../Repository/domain/Servicos";

const useObterPrazoBI = (codigoObra: string, codigoBI: string) => {
  const [prazoBI, setPrazoBI] = useState<any>([]);

  const fecthObterPrazoBI = useCallback(async () => {
    try {
      const prazo = await getPrazoBI(codigoObra, codigoBI);
      setPrazoBI(prazo);
    } catch (error) {}
  }, [codigoBI, codigoObra]);

  useEffect(() => {
    if (
      codigoObra &&
      codigoObra.trim() !== "" &&
      codigoBI &&
      codigoBI.trim() !== ""
    )
      fecthObterPrazoBI();
  }, [codigoBI, codigoObra, fecthObterPrazoBI]);

  return prazoBI;
};

export default useObterPrazoBI;
