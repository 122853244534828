export const validarFormulario = ({ novoPedido }: { novoPedido?: any; }, showAlertMessage: (message: string, type: "success" | "warning" | "danger" | "info") => void) => {
    const formularioValido =
        novoPedido?.codigoUsuario && novoPedido?.codigoEmpresa && novoPedido?.codigoObra;
    const quantitativosValidos = novoPedido?.quantitativos.length > 0;
    const orcamentosValidos = novoPedido?.orcamentos.itemSemOrcamento === true ? true : novoPedido?.orcamentos.orcamentoItens.length > 0;
    const orcamentoValido = !novoPedido?.orcamentos.itemSemOrcamento || (novoPedido?.orcamentos.justificativa && novoPedido?.orcamentos.justificativa.trim() == "");
    const dataAssinatura = novoPedido?.dataAssinatura;
    const dataInicio = novoPedido?.dataInicioExecucao;
    const isInclusaoServico = novoPedido?.descricaoTipoSolicitacao === "Inclusão de Serviço ao Escopo Contratual";
    const dataFim = novoPedido?.dataFimExecucao;
    const pedidoEmergencialValido =
        !novoPedido?.pedidoEmergencial || novoPedido?.justificativaSelecionada;

    const formValido =
        formularioValido &&
        quantitativosValidos &&
        orcamentosValidos &&
        orcamentoValido &&
        dataAssinatura &&
        dataInicio &&
        dataFim &&
        pedidoEmergencialValido;

    if (!formValido) {
        if (!formularioValido) {
            showAlertMessage(
                "Preencha todos os campos obrigatórios do formulário.",
                "danger"
            );
        } else if (!quantitativosValidos) {
            showAlertMessage("Verifique o campo de itens da solicitação/cotação", "danger");
        } else if (!orcamentosValidos) {
            showAlertMessage("Selecione o campo um item do orçamento.", "danger");
        } else if (!orcamentoValido) {
            showAlertMessage("Justificativa do orçamento é obrigatória.", "danger");
        } else if (!dataAssinatura) {
            showAlertMessage("Preencha o campo data necessaria para assinatura do contrato.", "danger");
        } else if (!dataInicio) {
            showAlertMessage("Preencha o prazo para execução do serviço (início):.", "danger");
        } else if (!dataFim) {
            showAlertMessage("Preencha o Prazo para execução do serviço (fim):.", "danger");
        } else if (!pedidoEmergencialValido) {
            showAlertMessage("Preencha a justificativa para pedido emergencial.", "danger");
        }
        return false;
    }

    if (isInclusaoServico) {
        if (!novoPedido?.relacaoProjetos) {
            showAlertMessage(
                "Preencha a relação dos projetos a serem encaminhados aos prestadores de serviço.",
                "danger"
            );
            return false;
        }
        if (!novoPedido?.especificacaoMateriais) {
            showAlertMessage(
                "Preencha a especificação dos materiais a serem aplicados nos serviços.",
                "danger"
            );
            return false;
        }
        if (!novoPedido?.nbrs) {
            showAlertMessage("Preencha as NBRs aplicáveis.", "danger");
            return false;
        }
        if (!novoPedido?.obrigacoesFornecedores) {
            showAlertMessage("Preencha as obrigações dos fornecedores.", "danger");
            return false;
        }
        if (!novoPedido?.fornecedoresIndicados) {
            showAlertMessage(
                "Preencha os fornecedores indicados (nome, telefone e e-mail).",
                "danger"
            );
            return false;
        }
        if (!novoPedido?.condicoesPagamentos) {
            showAlertMessage("Preencha as condições de pagamento.", "danger");
            return false;
        }
        if (!novoPedido?.criterioMedicao) {
            showAlertMessage("Preencha o critério de medição.", "danger");
            return false;
        }
        if (!novoPedido?.detalhamento) {
            showAlertMessage("Preencha o detalhamento do serviço.", "danger");
            return false;
        }
    }

    return true;
};