import { useState, useEffect, useCallback } from "react";
import { getObterEmpresaObraPorMatricula } from "../Repository/domain/Pedidos";
import useAlert from "./useAlert";

const useObterPedidoPorMatricula = (
  codigoUsuario: string,
  setLoading: (loading: boolean) => void
) => {
  const [obterEmpresaObraPorMatricula, setObterEmpresaObraPorMatricula] =
    useState<any>([]);
  const { showAlertMessage } = useAlert();

  const fetchObterPedidoPorMatricula = useCallback(async () => {
    try {
      setLoading(true);
      const pedidos = await getObterEmpresaObraPorMatricula(codigoUsuario);
      setObterEmpresaObraPorMatricula(pedidos);
    } catch (error) {
      showAlertMessage(" " + error, "warning");
    } finally {
      setLoading(false);
    }
  }, [codigoUsuario, setLoading, showAlertMessage]);

  useEffect(() => {
    fetchObterPedidoPorMatricula();
    // Função removida do array de dependencias por gerar loop infinito
  }, []);

  return obterEmpresaObraPorMatricula;
};

export default useObterPedidoPorMatricula;
