import React from "react";

const Spinner = () => {
  return (
    <>
      <div
        className="position-fixed top-0 start-0 w-100 h-100 bg-transparent"
        style={{ zIndex: 3 }} 
      ></div>

      <div className="d-flex justify-content-center position-fixed top-50 start-50 translate-middle" style={{ zIndex: 4 }}>
        <div
          className="spinner-border text-primary"
          style={{
            width: "6rem",
            height: "6rem",
          }}
          role="status"
        >
          <span className="visually-hidden">Carregando...</span>
        </div>
      </div>
    </>
  );
};

export default Spinner;
