import { useState, useEffect, useCallback } from "react";
import { getTipoDeSolicitacao } from "../Repository/domain/Pedidos";
import useAlert from "./useAlert";

const useTipoDeSolicitacao = () => {
  const [tipoDeSolicitacao, setTipoDeSolicitacao] = useState<any>([]);
  const { showAlertMessage } = useAlert();

  const fetchTipoDeSolicitacao = useCallback(async () => {
    try {
      const listaTipoDeSolicitacao = await getTipoDeSolicitacao();
      setTipoDeSolicitacao(listaTipoDeSolicitacao);
    } catch (error) {
      showAlertMessage(" " + error, "danger");
    }
  }, [showAlertMessage]);

  useEffect(() => {
    fetchTipoDeSolicitacao();
  }, [fetchTipoDeSolicitacao]);

  return tipoDeSolicitacao;
};

export default useTipoDeSolicitacao;
