import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAlert from "../../Hooks/useAlert";
import {
    useExibcaoCamposAprovacao,
    useFinalizacaoPedidoSup3,
    usePermissao,
    usePermissaoParaFinalizacao,
    usePermissaoSuprimentos4
} from "../../Hooks/usePermissao";
import { Anexo, AnexoDetalhado } from "../../Interface/IAnexos";
import { AtualizarPedidoProps, AtualizarServicoDetalhamentoSolicitacaoProps } from "../../Interface/IAtualizarPedido";
import {
    FinalizarPedido,
    VerificaDivergenciaContratual,
} from "../../Interface/IFinalizarPedido";
import { AtualizacaoFornecedorProps } from "../../Interface/IResumoContratacao";
import client from "../../Repository/config/client";
import {
    deleteRemoverAnexoPedido,
    postCancelar,
    postFinalizar,
    postVerificaDivergenciaContratual,
    putAtualizarAnexoPedido,
    putAtualizarDadosServicoDetalhamentoSolicitacao,
    putAtualizarFornecedor,
    putAtualizarPedido
} from "../../Repository/domain/Pedidos";
import { handleApiError } from "../../Utils/ErrorHandle";
import { validarValorContratacaoInicial, validarValorOrcamento } from "../../Utils/ValidarOrcamento";
import AlertaBootstrap from "../Alertas";
import ModalConfirmacao from "../ModalConfirmacao";

interface ParecerAprovacaoParametros {
    idPedido: number;
    contrato: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    anexoAtualizado: Anexo[];
    anexoRemovido: Anexo | undefined;
    anexosPedido: AnexoDetalhado[];
    dadosResumoFinanceiro: any[]
}

const ParecerAprovacaoTeste: React.FC<ParecerAprovacaoParametros> = ({
    idPedido,
    contrato,
    setLoading,
    anexoAtualizado,
    anexoRemovido,
    anexosPedido,
    dadosResumoFinanceiro
}: ParecerAprovacaoParametros) => {
    const navigate = useNavigate();
    const temPermissao = usePermissao();
    const exibirCampoAprovacao = useExibcaoCamposAprovacao();
    const permitirFinalizacao = usePermissaoParaFinalizacao();
    const temPermissaoSuprimentos4 = usePermissaoSuprimentos4();
    const finalizarPedidoSup3 = useFinalizacaoPedidoSup3();
    const [justificativaParecer, setJustificativaParecer] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [contratoUAU, setContratoUAU] = useState<string>("");
    const { showAlert, alertMessage, alertType, showAlertMessage } = useAlert();

    useEffect(() => {
        if (finalizarPedidoSup3) {
            setContratoUAU(contrato);
        }
    }, [finalizarPedidoSup3, contrato]);

    const handleParecerChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setJustificativaParecer(event.target.value);
    };

    const handleContratoUAUChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setContratoUAU(event.target.value);
    };

    const handleCancelarPedido = () => {
        if (justificativaParecer.trim() === "") {
            alert(
                "Por favor, é necessário fornecer uma justificativa para o cancelamento do pedido."
            );
            return;
        }
        setShowModal(true);
    };

    const pedidoAtualizado = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const pedidoAntigo = useSelector((state: any) => state.pedidoSliceViewModelSaida);

    const handleAtualizarPedido = async () => {
        const atualizarPedidoProps: AtualizarPedidoProps = {
            codigoPedido: pedidoAtualizado.codigoPedido,
            orcamentos: {
                orcamentoItens: pedidoAtualizado.orcamentos.orcamentoItens.map((orcamento: {
                    observacoes: any; codigoOrcamento: any; itemOrcamento: any; unidadeOrcada: any; quantidadeOrcada: any; valorUnitario: string; valorOrcamento: any; valorAjustado: any;
                }) => ({
                    codigoOrcamento: orcamento.codigoOrcamento,
                    itemOrcamento: orcamento.itemOrcamento,
                    unidadeOrcada: orcamento.unidadeOrcada,
                    quantidadeOrcada: Number(orcamento.quantidadeOrcada),
                    valorUnitario: (typeof orcamento.valorUnitario === 'number')
                        ? orcamento.valorUnitario
                        : Number(orcamento.valorUnitario.replace(/[R$ ]/g, '').replace(',', '.')),
                    valorOrcamento: Number(orcamento.valorOrcamento),
                    valorAjustado: Number(orcamento.valorAjustado),
                    observacoes: orcamento.observacoes
                })),
            },
            quantitativos: pedidoAtualizado.quantitativos.map((quantitativo: {
                observacoes: any; codigoComposicao: any; descricaoComposicao: any; unidade: any; quantidadeSolicitada: any; codigoPLM: any; categoriaUau: any;
            }) => ({
                codigoComposicao: quantitativo.codigoComposicao,
                descricaoComposicao: quantitativo.descricaoComposicao,
                unidade: quantitativo.unidade,
                quantidadeSolicitada: Number(quantitativo.quantidadeSolicitada),
                codigoPLM: quantitativo.codigoPLM,
                categoriaUau: quantitativo.categoriaUau,
                observacoes: quantitativo.observacoes
            }))

        };
        await putAtualizarPedido(atualizarPedidoProps);
    }

    const handleAtualizarServicoSolicitacao = async () => {
        const solicitacaoAlterada = pedidoAntigo.descricaoTipoSolicitacao != pedidoAtualizado.descricaoTipoSolicitacao;
        const servicoAlterado = pedidoAntigo.idServico != pedidoAtualizado.idServico;
        const detalhamentoAlterado = pedidoAntigo.detalhamento != pedidoAtualizado.detalhamento;
        const atualizarServicoSolicitacao: AtualizarServicoDetalhamentoSolicitacaoProps = {
            codigoPedido: pedidoAtualizado.codigoPedido,
            tipoSolicitacao: solicitacaoAlterada ? pedidoAtualizado.descricaoTipoSolicitacao : "",
            idServico: servicoAlterado ? pedidoAtualizado.idServico : 0,
            detalhamentoServico: detalhamentoAlterado ? pedidoAtualizado.detalhamento : "",
            ...(pedidoAtualizado.descricaoTipoSolicitacao != "Contratação Inicial" && {
                fornecedor: pedidoAtualizado.fornecedor,
                contratoUAU: pedidoAtualizado.contratoUAU,
                processoAnterior: pedidoAtualizado.processoAnterior,
                distrato: pedidoAtualizado.distrato,
                valorPagar: pedidoAtualizado.valorPagar,
            })
        };
        await putAtualizarDadosServicoDetalhamentoSolicitacao(atualizarServicoSolicitacao);
    }

    const handleAtualizarAnexo = async () => {
        try {
            await putAtualizarAnexoPedido(idPedido, anexoAtualizado);
        } catch (error) {
            console.error('Erro ao salvar itens.', error);
            alert("Erro ao atualizar anexo.");
        }
    };
    const handleRemoverAnexo = async () => {
        if (anexoRemovido) {
            try {
                await deleteRemoverAnexoPedido(idPedido, anexoRemovido);
            } catch (error) {
                console.error('Erro ao salvar itens.', error);
                alert("Erro ao remover anexo.");
            }
        }
    }

    const handleAtualizarFornecedor = async () => {
        const fornecedorAlterado = pedidoAntigo.fornecedores ? pedidoAntigo.fornecedores?.fornecedorVencedor : "" != pedidoAtualizado.fornecedores.fornecedorVencedor;
        const valorOrcamentoAlterado = pedidoAntigo.fornecedores ? pedidoAntigo.fornecedores?.valorOrcamento : "" != pedidoAtualizado.fornecedores.valorOrcamento;
        const valorContratacaoAlterado = pedidoAntigo.fornecedores ? pedidoAntigo.fornecedores?.valorContratacao : "" != pedidoAtualizado.fornecedores.valorContratacao;
        if (fornecedorAlterado || valorOrcamentoAlterado || valorContratacaoAlterado) {
            const dadosFornecedorAtualizado: AtualizacaoFornecedorProps = {
                codigoPedido: pedidoAtualizado.codigoPedido ?? 0,
                descricaoTipoSolicitacao: "",
                fornecedores: {
                    fornecedorVencedor: pedidoAtualizado.fornecedores.fornecedorVencedor,
                    cnpjFornecedor: pedidoAtualizado.fornecedores.cnpjFornecedor,
                    valorContratacao: pedidoAtualizado.fornecedores.valorContratacao,
                    valorOrcamento: pedidoAtualizado.fornecedores.valorOrcamento,
                },
            };
            await putAtualizarFornecedor(dadosFornecedorAtualizado);
        }
    }

    async function handleMovimentarPedido(
        aprovar: boolean,
        duvida: boolean
    ): Promise<void> {
        setLoading(true);
        const decisao = aprovar ? "Aprovar" : "Reprovar";

        if (justificativaParecer.trim() === "") {
            alert(
                `Por favor, é necessário fornecer uma justificativa para ${decisao} o pedido.`
            );
            setLoading(false);
            return;
        }

        const isCamposOrcamentoValidos = temPermissao && validarValorOrcamento(
            pedidoAtualizado,
            showAlertMessage,
            anexoAtualizado,
            anexosPedido,
            anexoRemovido
        );

        const isCamposContratacaoValidos = temPermissao && validarValorContratacaoInicial(
            pedidoAtualizado,
            showAlertMessage,
            dadosResumoFinanceiro
        );

        if (isCamposOrcamentoValidos || isCamposContratacaoValidos) {
            setLoading(false);
            return;
        }

        if ((pedidoAtualizado && pedidoAtualizado.orcamentos.orcamentoItens.length > 0) || (pedidoAtualizado && pedidoAtualizado.quantitativos.length > 0)) {
            handleAtualizarPedido();
        };

        handleAtualizarServicoSolicitacao();

        if (anexoAtualizado.length > 0) {
            await handleAtualizarAnexo();
        }

        if (anexoRemovido) {
            await handleRemoverAnexo();
        }

        if (pedidoAtualizado.fornecedores && isCamposOrcamentoValidos === false && isCamposContratacaoValidos === false) {
            handleAtualizarFornecedor();
        }

        const headers = {
            "Content-Type": "application/json",
        };

        const payload = JSON.stringify({
            numeroPedido: idPedido,
            aprovado: aprovar,
            justificativa: justificativaParecer,
            duvida: duvida,
        });

        try {
            const response = await client.post("Pedido/movimentar", payload, headers);

            if (response) {
                setLoading(false);
                return navigate("/pedidos");
            } else {
                alert(`Não foi possível ${decisao} pedido!`);
                setLoading(false);
                return;
            }
        } catch (error) {
            setLoading(false);
            alert(
                `Não foi possível ${decisao} o pedido! Erro: ${handleApiError(error)}`
            );
        } finally {
            setLoading(false);
        }
    }

    const verificaDivergenciaContratual = async () => {
        const payload: VerificaDivergenciaContratual = {
            numeroPedido: idPedido,
            contratoUAU: parseInt(contratoUAU, 10),
        };
        try {
            setLoading(true);
            const response = await postVerificaDivergenciaContratual(payload);
            return !!response;
        } catch (error) {
            alert(
                `Contrato UAU não encontrado. Verifique o número e tente novamente. Erro: " ${handleApiError(
                    error
                )}`
            );
            throw error;
        } finally {
            setLoading(false);
        }
    };

    const handleFinalizarPedido = async () => {
        try {
            setLoading(true);
            const payload: FinalizarPedido = {
                numeroPedido: idPedido,
                contratoUAU: parseInt(contratoUAU, 10),
                justificativa: justificativaParecer,
            };

            const response = await postFinalizar(payload);

            if (response) {
                alert(`Pedido ${idPedido} finalizado.`);
                setTimeout(() => {
                    navigate("/pedidos");
                }, 2500);
            } else {
                alert("Erro ao finalizar o pedido" + response);
            }
        } catch (error) {
            alert(
                `Não foi possível finalizar o pedido. Erro: " ${handleApiError(error)}`
            );
        } finally {
            setLoading(false);
        }
    };

    const confirmarCancelamento = async () => {
        try {
            setLoading(true);
            const payload = {
                numeroPedido: idPedido,
                justificativa: justificativaParecer,
            };
            const response = await postCancelar(payload);
            if (response) {
                return navigate("/pedidos");
            } else {
                alert(`Não foi possível cancelar o pedido ${idPedido}`);
            }
        } catch (error) {
            alert(
                `Não foi possível cancelar o pedido. Erro: " ${handleApiError(error)}`
            );
        } finally {
            setShowModal(false);
            setLoading(false);
        }
    };

    const isParecerValido = justificativaParecer.trim() !== "";
    const isFinalizarValido = contratoUAU.trim() !== "" && !!idPedido;

    const handleAprovarClick = async () => {
        if (finalizarPedidoSup3) {
            await handleFinalizarPedido();
        } else if (permitirFinalizacao) {
            if (contratoUAU.trim() === "") {
                alert("Por favor, forneça a justificativa e o número do contrato UAU.");
                return;
            }

            try {
                const hasDivergencia = await verificaDivergenciaContratual();
                if (hasDivergencia) {
                    alert(
                        "Divergência encontrada entre dados do contrato UAU e inseridos no sistema. Pedido encaminhado para etapa de justificativa."
                    );
                    if (justificativaParecer.trim() === "") {
                        alert(
                            "Foi identificado uma divergência entre os sistemas SCT e UAU, que pode se referir ao fornecedor ou ao valor informado. Sendo assim, a justificativa será encaminhada para que seja aprovado pela gerência."
                        );
                        return;
                    } else {
                        await handleFinalizarPedido();
                    }
                } else {
                    await handleFinalizarPedido();
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            await handleMovimentarPedido(true, false);
        }
    };

    return (
        <>
            {exibirCampoAprovacao && (
                <>
                    {showAlert && <AlertaBootstrap mensagem={alertMessage} tipo={alertType} />}
                    <h5>
                        {permitirFinalizacao
                            ? "Justificativa"
                            : "Parecer da aprovação ou reprovação"}
                    </h5>
                    {permitirFinalizacao && (
                        <div className="form-floating w-25 mb-3">
                            <input
                                type="number"
                                className="form-control"
                                id="ContratoUAU"
                                value={contratoUAU}
                                onChange={handleContratoUAUChange}
                            />
                            <label
                                htmlFor="ContratoUAU"
                                className="lh-1 fs-6 text-primary text-wrap"
                            >
                                Número contrato UAU <span className="text-danger">*</span>
                            </label>
                        </div>
                    )}
                    <div className="form-floating mt-3">
                        <textarea
                            className="form-control w-100 h-100"
                            id="justificativaParecerAprovacao"
                            value={justificativaParecer}
                            onChange={handleParecerChange}
                        ></textarea>
                        <label
                            className="lh-1 fs-6 text-primary text-wrap"
                            htmlFor="justificativaParecerAprovacao"
                        >
                            {permitirFinalizacao
                                ? "Justificativa"
                                : "Parecer Aprovar/Reprovar"}
                            <span className="text-danger">*</span>
                        </label>
                    </div>
                    <div className="d-flex justify-content-between">
                        {temPermissao ? (
                            <>
                                <div className="d-flex gap-4 w-100">
                                    <button
                                        className="btn btn-outline-success"
                                        disabled={!isParecerValido}
                                        onClick={handleAprovarClick}
                                    >
                                        Submeter MDE
                                    </button>
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => handleMovimentarPedido(false, false)}
                                        disabled={!isParecerValido}
                                    >
                                        Reprovar
                                    </button>
                                    <button
                                        className="btn btn-outline-warning ms-auto"
                                        disabled={!isParecerValido}
                                        onClick={handleCancelarPedido}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="d-flex gap-4 w-100">
                                {!permitirFinalizacao && (
                                    <button
                                        className="btn btn-outline-success"
                                        onClick={handleAprovarClick}
                                        disabled={!isParecerValido}
                                    >
                                        Aprovar
                                    </button>
                                )}
                                {permitirFinalizacao && (
                                    <button
                                        className="btn btn-outline-success"
                                        disabled={!isFinalizarValido}
                                        onClick={handleAprovarClick}
                                    >
                                        Finalizar pedido
                                    </button>
                                )}
                                {temPermissaoSuprimentos4 && (
                                    <button
                                        className="btn btn-outline-danger"
                                        onClick={() => handleMovimentarPedido(false, true)}
                                        disabled={!isParecerValido}
                                    >
                                        Esclarecer dúvida
                                    </button>
                                )}
                                <button
                                    className="btn btn-outline-danger"
                                    onClick={() => handleMovimentarPedido(false, false)}
                                    disabled={!isParecerValido}
                                >
                                    Reprovar
                                </button>
                                <button
                                    className="btn btn-outline-warning ms-auto"
                                    disabled={!isParecerValido}
                                    onClick={handleCancelarPedido}
                                >
                                    Cancelar
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            <ModalConfirmacao
                show={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={confirmarCancelamento}
            />
        </>
    );
};

export default ParecerAprovacaoTeste;
