import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { AutenticacaoService } from "../../Services/AutenticacaoService";
import { LoadingProps } from "../../Interface/ILoading";
import { jwtDecode } from "jwt-decode";
import TokenAcesso from "../../Interface/ITokenAcesso";
import useLogin from "../../Hooks/useLogin";

const Login: React.FC<LoadingProps> = ({ setLoading }) => {
  const { tela, tokenCognito, tokenGA, accessToken } = useParams();
  const { logado, alterarUsuarioLogado } = useLogin();
  const autenticacaoService = new AutenticacaoService();

  useEffect(() => {
    const autenticacao = async () => {
      setLoading(true);

      autenticacaoService.gravarTokens(
        tokenCognito as string,
        accessToken as string,
        tokenGA as string
      );

      const decoded = jwtDecode(tokenCognito as string) as TokenAcesso;
      await autenticacaoService.autenticar(decoded.email);

      alterarUsuarioLogado(true);
    };

    autenticacao()
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  });

  return logado ? <Navigate to={`/${tela}/`} /> : <></>;
};

export default Login;
