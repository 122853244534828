import { useState, useCallback } from "react";

const useAlert = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState<"success" | "warning" | "danger" | "info">("success");

  const showAlertMessage = useCallback((message: string, type: "success" | "warning" | "danger" | "info") => {
    setAlertMessage(message);
    setAlertType(type);
    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 7000);
  }, []);

  return { showAlert, alertMessage, alertType, showAlertMessage };
};

export default useAlert;
