import { useCallback, useEffect, useState } from "react";
import { getObterResumoFinanceiro } from "../Repository/domain/Pedidos";
import useAlert from "./useAlert";

const useResumoFinanceiro = (numeroPedido: number) => {
    const [resumoFinanceiro, setResumoFinanceiro] = useState<any[]>(
        []
    );
    const { showAlertMessage } = useAlert();
    const fetchResumoFinanceiro = useCallback(
        async (numeroPedido: number) => {
            try {
                const resumo: any = await getObterResumoFinanceiro(numeroPedido);
                setResumoFinanceiro(resumo);
            } catch (error: any) {
                showAlertMessage(
                    "Erro ao carregar os detalhes do histórico: " + error.message,
                    "warning"
                );
            }
        },
        [showAlertMessage]
    );
    useEffect(() => {
        fetchResumoFinanceiro(numeroPedido);
    }, [fetchResumoFinanceiro, numeroPedido]);

    return resumoFinanceiro;
};

export default useResumoFinanceiro;
