import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow
} from '@mui/material';
import React from 'react';
import { ResumoFinanceiroProps } from '../../Interface/IResumoFinanceiro';
import './style.scss';

const TabelaResumoFinanceiro: React.FC<ResumoFinanceiroProps> = ({ dadosResumoFinanceiro }) => {

    const columns = [
        { field: 'data', header: 'Data', width: 120, align: 'center' as const },
        { field: 'numSct', header: 'N° do SCT', width: 100 },
        { field: 'tipo', header: 'Tipo', width: 250 },
        { field: 'contratacaoInicial', header: 'Valor da contratação', width: 180 },
        { field: 'valorOrcado', header: 'Valor orçamento', width: 150 },
    ];

    const rows = (dadosResumoFinanceiro && Array.isArray(dadosResumoFinanceiro))
        ? dadosResumoFinanceiro
            .filter((resumoFinanceiro) => resumoFinanceiro.codigoPedido)
            .map((resumoFinanceiro) => ({
                id: resumoFinanceiro.codigoPedido,
                data: resumoFinanceiro.dataMovimentacao,
                numSct: resumoFinanceiro.codigoPedido,
                tipo: resumoFinanceiro.tipoSolicitacao,
                contratacaoInicial: resumoFinanceiro.valorContratacao,
                valorOrcado: resumoFinanceiro.valorOrcamento
            }))
        : [];

    const { valorContratacaoFinal, valorOrcamentoFinal } = dadosResumoFinanceiro[dadosResumoFinanceiro.length - 1] || {};

    const footer = [
        { field: '', valor: '', colIndex: 3 },
        { field: 'totalContratacaoInicial', valor: valorContratacaoFinal, colIndex: 1 },
        { field: 'totalOrcamento', valor: valorOrcamentoFinal, colIndex: 1 }
    ]

    return (
        <div className='table'>
            <h5>Resumo financeiro do processo</h5>
            <br />
            <TableContainer component={Paper} elevation={0}>
                <Table aria-label="Resumo Financeiro">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.field} style={{ width: column.width }}>
                                    {column.header}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={row.id} >
                                <TableCell>{row.data}</TableCell>
                                <TableCell>{row.numSct || '-'}</TableCell>
                                <TableCell>{row.tipo || '-'}</TableCell>
                                <TableCell>{row.contratacaoInicial || 'R$ 0,00'}</TableCell>
                                <TableCell>{row.valorOrcado || 'R$ 0,00'}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            {footer.map((column) => (
                                <TableCell key={column.field} colSpan={column.colIndex}>
                                    {column.valor}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default TabelaResumoFinanceiro;
