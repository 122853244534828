import { TextField } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { BsDownload } from "react-icons/bs";
import { IoMdRemove } from "react-icons/io";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Anexo, AnexoDetalhado, tipoDeAnexo } from "../../Interface/IAnexos";
import { getListaTipoAnexo } from "../../Repository/domain/Pedidos";

interface AnexosProps {
    anexosIniciais?: AnexoDetalhado[];
    onAnexosChange?: (anexos: Anexo[]) => void;
    setAnexoAtualizado?: React.Dispatch<React.SetStateAction<Anexo[]>>;
    setAnexoRemovido?: React.Dispatch<React.SetStateAction<Anexo | undefined>>;
}
const AnexosTeste: React.FC<AnexosProps> = ({
    anexosIniciais = [],
    onAnexosChange,
    setAnexoAtualizado,
    setAnexoRemovido
}) => {
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const usuarioLogado = localStorage.getItem('codigoUsuario');
    const [listaDeAnexos, setListaDeAnexos] = useState<tipoDeAnexo[]>([]);
    const [tipoDocumentoSelecionado, setTipoDocumentoSelecionado] = useState<string>("");
    const [, setNomeArquivo] = useState<string>("");
    const [anexos, setAnexos] = useState<Anexo[]>([]);
    const location = useLocation();
    const { acao } = location.state || {};

    const memoizedAnexosIniciais = useMemo(
        () => anexosIniciais,
        [anexosIniciais]
    );

    useEffect(() => {
        const fetchAnexos = async () => {
            try {
                const anexos: any =
                    (await getListaTipoAnexo()) as unknown as tipoDeAnexo;
                setListaDeAnexos(anexos);
            } catch (error) {
                alert("Erro ao listar opções de anexo:" + error);
            }
        };
        fetchAnexos();
    }, []);

    useEffect(() => {
        if (mode === "view" && memoizedAnexosIniciais) {
            const formattedAnexos = memoizedAnexosIniciais.map((anexo, index) => ({
                id: index,
                tipoDocumento: anexo.tipoArquivo,
                nomeArquivo: anexo.nomeArquivo,
                url: anexo.url,
                usuarioCadastro: anexo.usuarioCadastro
            }));
            setAnexos(formattedAnexos);
        }
    }, [mode, memoizedAnexosIniciais]);

    const handleTipoDocumentoChange = (
        event: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setTipoDocumentoSelecionado(event.target.value);
    };

    const handleArquivoSelecionado = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setNomeArquivo(file.name);
            const novoAnexo = {
                id: new Date().getTime(),
                tipoDocumento: tipoDocumentoSelecionado,
                nomeArquivo: file.name,
                arquivo: file,
            };
            const novosAnexos = [...anexos, novoAnexo];
            setAnexos(novosAnexos);
            setTipoDocumentoSelecionado("");
            setNomeArquivo("");
            if (mode === "create") {
                onAnexosChange?.(novosAnexos);
            }
        }
    };

    const removerAnexo = async (id: number) => {
        const novosAnexos = anexos.filter((anexo) => anexo.id !== id);
        const anexoRemovido = anexos.filter((anexo) => anexo.id === id)[0];
        setAnexos(novosAnexos);
        if (anexoRemovido && setAnexoRemovido && mode == 'view' && usuarioLogado?.toLowerCase() === anexoRemovido.usuarioCadastro?.toLowerCase()) {
            setAnexoRemovido(anexoRemovido)
        }
        // Chame o callback apenas se o modo for "create"
        if (mode === "create") {
            onAnexosChange?.(novosAnexos);
        }
    };

    useEffect(() => {
        if (anexos && setAnexoAtualizado && anexosIniciais.length !== anexos.length) {
            setAnexoAtualizado(anexos);
        }
    }, [anexos]);
    const botaoRemoverDesabilitado = (anexo: Anexo) => {
        const anexoInicial = anexosIniciais.find(
            (anexoInicial) => anexoInicial.nomeArquivo === anexo.nomeArquivo && anexoInicial.tipoArquivo === anexo.tipoDocumento
        );
        if (anexoInicial && acao) {
            return !(anexo.usuarioCadastro && anexo.usuarioCadastro.toLowerCase() === usuarioLogado?.toLowerCase());
        }
        if (acao === false) {
            return true;
        }
        return false;
    };

    return (
        <div>
            {((mode === 'create') || (anexos.length > 0) || (acao)) && (
                <>
                    <hr />
                    <h5>Anexos</h5>
                    <br />
                </>
            )}
            {((mode === 'view' && acao) || (mode === 'create')) && (
                <div className="d-flex gap-2">
                    <div className="form-floating w-50">
                        <select
                            className="form-select"
                            id="TipoDocumento"
                            aria-label="Floating label select example"
                            value={tipoDocumentoSelecionado}
                            onChange={handleTipoDocumentoChange}
                        >
                            <option value="">Selecione...</option>
                            {listaDeAnexos
                                .slice()
                                .sort((a, b) => a.tipoAnexo.localeCompare(b.tipoAnexo))
                                .map((anexo) => (
                                    <option key={anexo.id} value={anexo.tipoAnexo}>
                                        {anexo.tipoAnexo}
                                    </option>
                                ))}
                        </select>
                        <label className="text-primary" htmlFor="TipoDocumento">
                            Tipo de Documento
                        </label>
                    </div>
                    <div className="form-floating mb-3 w-50">
                        <div className="formulario">
                            <TextField
                                sx={{ '& .MuiInputBase-input': { cursor: 'pointer', padding: '8px 10px 15px', fontSize: '14px' } }}
                                type="file"
                                className="inputForm"
                                id="CampoArquivos"
                                onChange={handleArquivoSelecionado}
                                disabled={!tipoDocumentoSelecionado}
                                label="Nome do arquivo"
                                slotProps={{ inputLabel: { shrink: false } }}
                            />
                        </div>
                    </div>
                </div>
            )}
            {((mode === 'view' && anexosIniciais.length > 0) || (mode === 'create') || ((mode === 'view' && acao))) && (
                <table className="table table-striped text-center table-hover table-bordered border-lightinfo">
                    <thead>
                        <tr className="table-primary text-center">
                            <th scope="col">Tipo de documento</th>
                            <th scope="col">Nome do arquivo</th>
                            <th scope="col">Ação</th>
                        </tr>
                    </thead>
                    <tbody>
                        {anexos.map((anexo) => (
                            <tr key={anexo.id}>
                                <td align="left">{anexo.tipoDocumento}</td>
                                <td align="left">{anexo.nomeArquivo}</td>
                                <td>
                                    <div className="d-flex gap-3 justify-content-center">
                                        {mode === "view" && (
                                            <a href={anexo.url} download>
                                                <button type="button" className="btn btn-outline-primary">
                                                    <BsDownload />
                                                </button>
                                            </a>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-outline-danger"
                                            onClick={() => removerAnexo(anexo.id)}
                                            disabled={botaoRemoverDesabilitado(anexo)}
                                        >
                                            <IoMdRemove />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default AnexosTeste;
