import { TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePermissao } from "../../Hooks/usePermissao";
import { setItem } from "../../redux/pedido/sliceViewModelEntrada";
import InputAutoCompleteFornecedor from "../InputAutoCompleteFornecedor";
import { InputNumericoMoeda } from "../InputNumericoMoeda/inputNumerico";

const ResumoContratacaoTeste: React.FC = () => {
    const temPermissao = usePermissao();
    const dadosPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const dispatch = useDispatch();

    const handleDetalhamentoChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        dispatch(setItem({ ...dadosPedido, [name]: value }));
    };

    return (
        <div className="formulario">
            <h5>Dados da contratação</h5>
            <div className="d-flex gap-4">
                <TextField
                    className="inputForm"
                    label="Solicitação:"
                    disabled
                    value={dadosPedido?.codigoPedido}
                />
                <TextField
                    className="inputForm"
                    label="Obra:"
                    disabled
                    value={dadosPedido?.codigoObra}
                />
                <InputNumericoMoeda />
            </div>
            <div className="d-flex gap-4 mt-3">
                <TextField
                    className="inputForm"
                    label="Data de assinatura do contrato:"
                    disabled
                    type="date"
                    value={dadosPedido?.dataAssinatura}
                />
                <TextField
                    className="inputForm"
                    label="Tipo de serviço contratado:"
                    disabled
                    value={dadosPedido?.descricaoServico}
                />
                <div className="col-6">
                    <InputAutoCompleteFornecedor dadosContratacao={dadosPedido?.fornecedores} />
                </div>
            </div>
            <div className="form-floating mt-3">
                <TextField
                    disabled={!temPermissao}
                    value={dadosPedido?.detalhamento || ''}
                    multiline
                    rows={2}
                    slotProps={{ inputLabel: { shrink: false, } }}
                    onChange={handleDetalhamentoChange}
                    className="inputDetalhamento"
                    name="detalhamento"
                    label={<>Detalhamento do serviço <span style={{ color: 'red' }}>*</span></>}
                ></TextField>
            </div>
        </div>
    );
};

export default ResumoContratacaoTeste;