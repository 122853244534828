import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { usePermissao } from "../../Hooks/usePermissao";
import { Fornecedor } from "../../Interface/IFornecedorUAU";
import { getObterFornecedoresNoUAU } from "../../Repository/domain/Pedidos";
import { setDadosContratacao } from "../../redux/pedido/sliceViewModelEntrada";

const formatarCNPJ = (value: string): string => {
    const cleanedValue = value.replace(/\D/g, "");
    if (cleanedValue.length === 14) {
        return cleanedValue.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
        );
    } else if (cleanedValue.length === 11) {
        return cleanedValue.replace(
            /(\d{3})(\d{3})(\d{3})(\d{2})/,
            "$1.$2.$3-$4"
        )
    }
    return value;
};
interface InputAutoCompleteFornecedorProps {
    dadosContratacao: any;
}

const InputAutoCompleteFornecedor: React.FC<InputAutoCompleteFornecedorProps> = ({ dadosContratacao }) => {
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState<readonly any[]>([]);
    const [loading, setLoading] = useState(false);
    const [inputValue, setInputValue] = useState<string>(dadosContratacao?.cnpjNomeFornecedor || "");
    const [inputDisabled, setInputDisabled] = useState(false);
    const [initialOptions, setInitialOptions] = useState<Fornecedor[]>([]);
    const dispatch = useDispatch();
    const temPermissao = usePermissao();

    const handleOpen = async (event: React.KeyboardEvent<HTMLDivElement>) => {
        const currentValue = (event.target as HTMLInputElement).value;
        if (event.key === "Enter" && currentValue.length >= 3) {
            setLoading(true);
            setInputDisabled(true);
            try {
                const res = await getObterFornecedoresNoUAU(inputValue);
                const dadosFormatados = res.filter(
                    (item: Fornecedor) =>
                        item.fornecedor.toLowerCase().includes(inputValue.toLowerCase()) ||
                        item.documento.includes(inputValue)
                ).map((item) => ({
                    ...item,
                    label: `${formatarCNPJ(item.documento)} - ${item.fornecedor}`,
                    key: `${item.codigoFornecedor}-${item.documento}`
                }));
                setInitialOptions(dadosFormatados);
                setOptions(dadosFormatados);
                setOpen(true);
            } finally {
                setLoading(false);
                setInputDisabled(false);
            }
        }
    };

    const handleInputChangeAutoComplete = (_event: any, newInputValue: string) => {
        if (inputDisabled) return;
        setInputValue(newInputValue);
        if (newInputValue === "") {
            setOptions([]);
            setInitialOptions([]);
            setOpen(false);
        } else if (newInputValue.length >= 3 && initialOptions.length > 0) {
            const filteredOptions = initialOptions.filter((item: Fornecedor) =>
                item.fornecedor.toLowerCase().includes(newInputValue.toLowerCase())
            );
            setOptions(filteredOptions);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setOptions([]);
    };

    useEffect(() => {
        if (dadosContratacao?.cnpjFornecedor) {
            setOptions(prevOptions => [
                ...prevOptions,
                {
                    documento: dadosContratacao.cnpjFornecedor,
                    label: `${formatarCNPJ(dadosContratacao.cnpjFornecedor)} - ${dadosContratacao.fornecedorVencedor}` || null
                }
            ]);
        }
    }, [dadosContratacao]);

    return (
        <Autocomplete
            className="inputForm"
            value={options.find((option: { documento: any; }) => option.documento === dadosContratacao?.cnpjFornecedor) || null}
            disabled={!temPermissao}
            open={open}
            onOpen={() => inputValue.length >= 3 && setOpen(true)}
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => option.codigoFornecedor === value.codigoFornecedor}
            getOptionLabel={(option) => option.label}
            options={options}
            loading={loading}
            inputValue={inputValue}
            onInputChange={handleInputChangeAutoComplete}
            onKeyDown={handleOpen}
            onChange={(_event, newValue) => {
                if (newValue) {
                    const mappedValue = {
                        idPedido: dadosContratacao?.codigoPedido || 0,
                        valorContratacao: dadosContratacao?.valorContratacao || '',
                        valorOrcamento: dadosContratacao?.valorOrcamento || '',
                        fornecedorVencedor: newValue.fornecedor,
                        cnpjFornecedor: newValue.documento,
                        cnpjNomeFornecedor: newValue.label,
                    };
                    dispatch(setDadosContratacao(mappedValue));
                }
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    disabled={inputDisabled || loading || !temPermissao}
                    label={<>Fornecedor vencedor (qualificado): <span style={{ color: 'red' }}>*</span></>}
                    slotProps={{
                        inputLabel: { shrink: false },
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        },
                    }}
                />
            )}
            renderOption={(props, option) => (
                <li {...props}>
                    {loading && options.length === 0 ? (
                        <CircularProgress size={20} style={{ marginRight: 8 }} />
                    ) : (
                        option.label
                    )}
                </li>
            )}
            popupIcon={null}
            noOptionsText={"Nenhuma opção"}

        />
    )
}
export default InputAutoCompleteFornecedor;