import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { usePermissao } from '../../Hooks/usePermissao';
import { setDadosContratacao } from '../../redux/pedido/sliceViewModelEntrada';
import "./style.scss";

export const InputNumericoMoeda: React.FC = () => {
    const temPermissao = usePermissao();
    const dadosContratacao = useSelector((state: any) => state.pedidoSliceViewModelEntrada.fornecedores);
    const dispatch = useDispatch();

    const handleInputChange = (
        values: { formattedValue: string; value: string; floatValue?: number },
        name: string
    ) => {
        dispatch(setDadosContratacao({ ...dadosContratacao, [name]: values.formattedValue }));
    };

    return (
        <>
            <div className="inputContainer">
                <div className="inputWrapper">
                    <label className="floatingLabel">
                        Valor da contratação: <span style={{ color: 'red' }}>*</span>
                    </label>
                    <NumericFormat
                        className='inputNumerico'
                        value={dadosContratacao?.valorContratacao}
                        onValueChange={(values) => handleInputChange(values, 'valorContratacao')}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator=','
                        thousandSeparator='.'
                        prefix='R$ '
                        placeholder='R$ 0,00'
                        disabled={!temPermissao}
                    />
                </div>
            </div>
            <div className="inputContainer">
                <div className="inputWrapper">
                    <label className="floatingLabel">
                        Valor do orçamento: <span style={{ color: 'red' }}>*</span>
                    </label>
                    <NumericFormat
                        className='inputNumerico'
                        value={dadosContratacao?.valorOrcamento}
                        onValueChange={(values) => handleInputChange(values, 'valorOrcamento')}
                        decimalScale={2}
                        fixedDecimalScale
                        decimalSeparator=','
                        thousandSeparator='.'
                        prefix='R$ '
                        placeholder='R$ 0,00'
                        disabled={!temPermissao}
                    />
                </div>
            </div>
        </>
    );
};
