export const validarValorOrcamento = (
    pedidoAtualizado: any,
    showAlertMessage: (message: string, type: "success" | "warning" | "danger" | "info") => void,
    anexoAtualizado: any,
    anexosPedido: any,
    anexoRemovido: any) => {
    if (pedidoAtualizado.fornecedores === null) {
        showAlertMessage(
            "Preencha todos os dados obrigatórios da contratação.",
            "danger"
        );
        return true;
    }
    if (pedidoAtualizado.fornecedores?.valorContratacao === "" || pedidoAtualizado.fornecedores?.valorContratacao === undefined) {
        showAlertMessage(
            "Preencha o valor da contratação.",
            "danger"
        );
        return true;
    }
    if (pedidoAtualizado.fornecedores?.valorOrcamento === "" || pedidoAtualizado.fornecedores?.valorOrcamento === undefined) {
        showAlertMessage(
            "Preencha o valor do orçamento.",
            "danger"
        );
        return true;
    }
    if (pedidoAtualizado.fornecedores?.fornecedorVencedor === "" ||
        pedidoAtualizado.fornecedores?.fornecedorVencedor === undefined) {
        showAlertMessage(
            "Preencha o fornecedor.",
            "danger"
        );
        return true;
    }
    if (pedidoAtualizado.detalhamento === "") {
        showAlertMessage(
            "O detalhamento não pode ser vazio.",
            "danger"
        );
        return true;
    }
    if (anexoAtualizado.length === 0 && !(anexosPedido.some((anexo: { tipoArquivo: string }) => anexo.tipoArquivo === "MDE - Mapa de Equalização"))) {
        showAlertMessage(
            "É necessário anexar o Mapa de Equalização (MDE).",
            "danger"
        );
        return true
    }
    if (anexoAtualizado.length > 0 && !(anexoAtualizado.some((anexo: { tipoDocumento: string }) => anexo.tipoDocumento === "MDE - Mapa de Equalização"))) {
        showAlertMessage(
            "É necessário anexar o Mapa de Equalização (MDE).",
            "danger"
        );
        return true
    }
    if (anexoRemovido && anexoAtualizado.length === 0) {
        showAlertMessage(
            "É necessário anexar o Mapa de Equalização (MDE).",
            "danger"
        );
        return true
    }
    return false;
}

export const validarValorContratacaoInicial = (
    pedidoAtualizado: any,
    showAlertMessage: (message: string, type: "success" | "warning" | "danger" | "info") => void,
    dadosResumoFinanceiro: any
) => {
    const { valorContratacaoFinal, valorOrcamentoFinal } = dadosResumoFinanceiro[dadosResumoFinanceiro.length - 1] || {};
    const valorFormatado = (value: string): number => {
        if (!value) return 0;
        const valorLimpo = value.replace(/\s/g, '').replace(/[^0-9,]/g, '');
        const valorComPonto = valorLimpo.replace(',', '.');
        const valorNumerico = parseFloat(valorComPonto);
        return isNaN(valorNumerico) ? 0 : valorNumerico;
    };

    if ((pedidoAtualizado.fornecedores?.valorContratacao)?.includes('-') &&
        (valorFormatado((pedidoAtualizado.fornecedores?.valorContratacao)) > valorFormatado(valorContratacaoFinal))) {
        showAlertMessage(
            "O valor da contratação informado não é permitido.",
            "danger"
        );
        return true;
    }
    if ((pedidoAtualizado.fornecedores?.valorOrcamento)?.includes('-') &&
        (valorFormatado(pedidoAtualizado.fornecedores?.valorOrcamento) > valorFormatado(valorOrcamentoFinal))) {
        showAlertMessage(
            "O valor do orçamento informado não é permitido.",
            "danger"
        );
        return true;
    }
    if ((pedidoAtualizado.fornecedores?.valorOrcamento)?.includes('-') &&
        (pedidoAtualizado.descricaoTipoSolicitacao === "Contratação Inicial")) {
        showAlertMessage(
            "O valor do orçamento não pode ser negativo.",
            "danger"
        );
        return true;
    }
    if ((pedidoAtualizado.fornecedores?.valorContratacao)?.includes('-') &&
        (pedidoAtualizado.descricaoTipoSolicitacao === "Contratação Inicial")) {
        showAlertMessage(
            "O valor da contratação não pode ser negativo.",
            "danger"
        );
        return true;
    }
    return false;
}
