import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AlertaBootstrap from "../../components/Alertas";
import AnexosTeste from "../../components/Anexos/anexosTeste";
import BuscarCampoTeste from "../../components/BuscarCampos/buscarCamposTeste";
import OpcaoInsumoComposicaoTeste from "../../components/CheckboxesInsumoComposicao/opcaoInsumoComposicaoteste";
import DataAssinaturaTeste from "../../components/DataAssinatura/dataAssinaturaTeste";
import DetalhesPedidoTeste from "../../components/DetalhesPedido/detalhesdopedidoteste";
import FormularioTeste from "../../components/Formulario/formularioteste";
import ItemSemOrcamentoTeste from "../../components/ItemSemOrcamento/itemSemOrcamentoteste";
import Navbar from "../../components/Navbar";
import TabelaOrcamentosTeste from "../../components/TabelaOrcamentos/tabelaOrcamentosteste";
import TabelaQuantitativosTeste from "../../components/TabelaQuantitativos/tabelaQuantitavivosTeste";
import useAlert from "../../Hooks/useAlert";
import { useFormData } from "../../Hooks/useFormData";
import useObterPedidoPorMatricula from "../../Hooks/useObterPedidoPorMatricula";
import useObterPrazoBI from "../../Hooks/useObterPrazoBI";
import useServicosComposicoesInsumos from "../../Hooks/useServicosComposicoesInsumos";
import usePedidoState from "../../Hooks/useStateCriarPedido";
import { setDesabilitado, setMode } from "../../redux/pedido/slice";
import { setItem } from "../../redux/pedido/sliceViewModelEntrada";
import { postCadastrar } from "../../Repository/domain/Pedidos";
import { getDataAtual } from "../../Utils/DataAtual";
import { validarFormulario } from "../../Utils/ValidarFormulario";

interface CriarPedidoTesteProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CriarPedidoTeste: React.FC<CriarPedidoTesteProps> = ({ setLoading }) => {
    const { numeroPedido } = useParams<{ numeroPedido: string }>();
    const numeroPedidoAsNumber = parseInt(numeroPedido ?? "0", 10);
    const mode = useSelector((state: any) => state.modeSlice.mode);
    const location = useLocation();
    const desabilitadoTabela = useSelector((state: any) => state.modeSlice.desabilitadoTabela);
    const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const obterPrazoBI = useObterPrazoBI(novoPedido.codigoObra, novoPedido.codigoServicoBI);
    const dispatch = useDispatch();
    const { arquivos, setArquivos, codigoUsuario } = usePedidoState();
    const [prazoContratacao, setPrazoContratacao] = useState<string>("");
    const { showAlert, alertMessage, alertType, showAlertMessage } = useAlert();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname.includes("visualizar") && mode === "view") {
            localStorage.setItem("navegar", "true");
        } else if (location.pathname.includes("solicitar")) {
            dispatch(setMode("create"));
            dispatch(setDesabilitado(false));
        }
    }, [])

    const { estado } = useFormData(
        novoPedido?.codigoEmpresa.toString(),
        novoPedido?.codigoObra
    );

    const obrasPorMatricula = useObterPedidoPorMatricula(
        codigoUsuario,
        setLoading
    );

    useEffect(() => {
        if (obterPrazoBI == "" && mode === "create") {
            const prazoPadrao = new Date();
            prazoPadrao.setDate(prazoPadrao.getDate() + 45);
            setPrazoContratacao(prazoPadrao.toISOString().split("T")[0]);
            dispatch(setItem({ ...novoPedido, prazoContratacao: prazoPadrao.toISOString().split("T")[0] }));
        } else {
            setPrazoContratacao(obterPrazoBI);
        }
        if (codigoUsuario !== "" && estado !== "" && obterPrazoBI && mode === "create") {
            dispatch(setItem({
                ...novoPedido,
                estado: estado,
                codigoUsuario: codigoUsuario,
                prazoContratacao: prazoContratacao,
            }));
        }
    }, [estado]);

    useEffect(() => {
        if (mode === "create") {
            dispatch(setItem({ ...novoPedido, dataPedido: getDataAtual() }));
        }
    }, []);

    const handleCriarPedido = async () => {
        dispatch(setItem({
            ...novoPedido,
            estado: estado,
            codigoUsuario: codigoUsuario,
            prazoContratacao: prazoContratacao,
        }));
        const isValid = validarFormulario({ novoPedido }, showAlertMessage);
        if (isValid) {
            setLoading(true);
            const dadosPedido = { pedido: novoPedido };
            const formData = new FormData();
            formData.append('pedido', JSON.stringify(dadosPedido));
            arquivos.forEach((arquivo) => {
                const nomeArquivoFormatado = `${arquivo.tipoDocumento}_${arquivo.nomeArquivo}`;
                if (arquivo.arquivo) {
                    formData.append(`arquivos`, arquivo.arquivo, nomeArquivoFormatado);
                }
            });
            try {
                const response = await postCadastrar(formData);
                const { success, data } = response;
                if (success) {
                    showAlertMessage(`Pedido ${data.numeroPedido} criado.`, "success");
                    setTimeout(() => {
                        navigate("/pedidos");
                    }, 5000);
                } else {
                    showAlertMessage("Erro ao criar pedido" + response.error, "warning");
                }
            } catch (error: any) {
                alert("Erro ao criar pedido" + error.message);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleAnexosChange = (
        novosAnexos: { id: number; tipoDocumento: string; nomeArquivo: string }[]
    ) => {
        setArquivos(novosAnexos);
    };

    const { servicos, insumos, composicoes } = useServicosComposicoesInsumos("");
    return (
        <div className="d-flex flex-column gap-4 w-100" style={{ marginTop: mode === "create" ? "40px" : "0px" }}>
            {showAlert && <AlertaBootstrap mensagem={alertMessage} tipo={alertType} />}
            {mode === "view" && (
                <Navbar numeroPedido={numeroPedidoAsNumber} anexoAtualizado={[]} />
            )}
            <FormularioTeste servicos={servicos} obrasPorMatricula={obrasPorMatricula} />
            {(mode === "create" || (mode === "view" && desabilitadoTabela === false)) && (
                <OpcaoInsumoComposicaoTeste insumos={insumos} composicoes={composicoes} />
            )}
            <TabelaQuantitativosTeste />
            <hr />
            <ItemSemOrcamentoTeste />
            {
                novoPedido?.orcamentos?.itemSemOrcamento === false && (
                    <>
                        {(mode === "create" || (mode === "view" && desabilitadoTabela === false)) && (
                            <BuscarCampoTeste composicoes={composicoes} />)}
                        <TabelaOrcamentosTeste />
                    </>
                )
            }
            <hr />
            <DataAssinaturaTeste />
            <hr />
            <DetalhesPedidoTeste />
            {mode === "create" && (
                <div>
                    <AnexosTeste
                        anexosIniciais={[]}
                        onAnexosChange={handleAnexosChange}
                        setAnexoAtualizado={undefined} />
                    <hr />
                </div>
            )}
            {mode === "create" && (
                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        className="btn btn-outline-primary w-25"
                        onClick={handleCriarPedido}
                    >
                        Enviar
                    </button>
                </div>
            )}
        </div >
    );
}

export default CriarPedidoTeste;
