import { TextField, TextFieldProps } from "@mui/material";
import { useRef } from "react";

type DelayProps = {
    handleDelay: (value: string, itemId: number) => void;
    delayTimeout: number;
    itemId: number;
};

export function DelayInput(props: TextFieldProps & DelayProps) {
    const { handleDelay, delayTimeout, itemId, ...rest } = props;
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        clearTimeout(timerRef.current);
        const { value } = event.target;
        timerRef.current = setTimeout(() => {
            handleDelay(value, itemId);
        }, delayTimeout);
    };

    return <TextField {...rest} onChange={handleChange} className="inputTabela" />;
}