export const handleApiError = (error: any) => {
  let message = "Ocorreu um erro desconhecido.";
  if (error.response) {
    message = error.response.data.message || error.response.statusText;
  } else if (error.request) {
    message =
      "Não foi possível conectar ao servidor. Por favor, tente novamente mais tarde.";
  } else {
    message = error.message;
  }
  return message;
};
