import { useCallback, useEffect, useState } from "react";
import { historicoPedido } from "../Interface/IHistoricoPedido";
import { getObterHistoricoDoPedido } from "../Repository/domain/Pedidos";
import useAlert from "./useAlert";

const useHistoricoDoPedido = (numeroPedido: number) => {
    const [historicoDoPedido, setHistoricoDoPedido] = useState<historicoPedido[]>(
        []
    );
    const { showAlertMessage } = useAlert();
    const fetchHistoricoDoPedido = useCallback(
        async (numeroPedido: number) => {
            try {
                if (numeroPedido === 0) {
                    return;
                } else {
                    const historico: any = await getObterHistoricoDoPedido(numeroPedido);
                    setHistoricoDoPedido(historico);
                }
            } catch (error: any) {
                showAlertMessage(
                    "Erro ao carregar os detalhes do histórico: " + error.message,
                    "warning"
                );
            }
        },
        [showAlertMessage]
    );
    useEffect(() => {
        fetchHistoricoDoPedido(numeroPedido);
    }, [fetchHistoricoDoPedido, numeroPedido]);

    return historicoDoPedido;
};

export default useHistoricoDoPedido;
