export type Estado = {
    sigla: string;
    descricao: string;
};

export const estados: Estado[] = [
    { sigla: "AC", descricao: "Acre" },
    { sigla: "AL", descricao: "Alagoas" },
    { sigla: "AP", descricao: "Amapá" },
    { sigla: "AM", descricao: "Amazonas" },
    { sigla: "BA", descricao: "Bahia" },
    { sigla: "CE", descricao: "Ceará" },
    { sigla: "DF", descricao: "Distrito Federal" },
    { sigla: "ES", descricao: "Espírito Santo" },
    { sigla: "GO", descricao: "Goiás" },
    { sigla: "MA", descricao: "Maranhão" },
    { sigla: "MT", descricao: "Mato Grosso" },
    { sigla: "MS", descricao: "Mato Grosso do Sul" },
    { sigla: "MG", descricao: "Minas Gerais" },
    { sigla: "PA", descricao: "Pará" },
    { sigla: "PB", descricao: "Paraíba" },
    { sigla: "PR", descricao: "Paraná" },
    { sigla: "PE", descricao: "Pernambuco" },
    { sigla: "PI", descricao: "Piauí" },
    { sigla: "RJ", descricao: "Rio de Janeiro" },
    { sigla: "RN", descricao: "Rio Grande do Norte" },
    { sigla: "RS", descricao: "Rio Grande do Sul" },
    { sigla: "RO", descricao: "Rondônia" },
    { sigla: "RR", descricao: "Roraima" },
    { sigla: "SC", descricao: "Santa Catarina" },
    { sigla: "SP", descricao: "São Paulo" },
    { sigla: "SE", descricao: "Sergipe" },
    { sigla: "TO", descricao: "Tocantins" },
];

export type Etapa = {
    id: number;
    descricao: string;
};

export const etapaSolicitacao: Etapa[] = [
    { id: 1, descricao: "Solicitação de serviço" },
    { id: 2, descricao: "Aprovação da solicitação do serviço" },
    { id: 3, descricao: "Montagem do mapa" },
    { id: 4, descricao: "Aprovação do mapa" },
    { id: 5, descricao: "Contratação de serviço" },
    { id: 6, descricao: "Aprovação da justificativa" },
    { id: 7, descricao: "Minuta contratual" },
];


export type Responsavel = {
    responsavel: string;
};

export const responsavelPelaEtapa: Responsavel[] = [
    { responsavel: "Solicitante" },
    { responsavel: "Aprovador 0" },
    { responsavel: "Aprovador 1" },
    { responsavel: "Aprovador 2" },
    { responsavel: "Aprovador 2 (MDE)" },
    { responsavel: "Aprovador 3" },
    { responsavel: "Suprimentos 1" },
    { responsavel: "Suprimentos 2" },
    { responsavel: "Suprimentos 3" },
    { responsavel: "Suprimentos 4" },
];
