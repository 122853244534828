import client from "../../config/client";
import { CodigoPLM } from "../../../Interface/ICodigoPLM";
import { AxiosResponse } from "axios";

export const getServicos = async () => {
  try {
    const res = await client.get("Servico/listarTodos");
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getFornecedores = async (
  codigoEmpresa: string,
  codigoObra: string
) => {
  try {
    const res = await client.get(
      `Servico/buscar-fornecedores-por-empresa-obra?codigoEmpresa=${codigoEmpresa}&codigoObra=${codigoObra}`
    );
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getComposicoes = async () => {
  try {
    const res = await client.get("Servico/listarComposicoes");
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getInsumos = async () => {
  try {
    const res = await client.get("Servico/listarInsumos");
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
export const getCodigoPLM = async (
  codigoEmpresa: string,
  codigoObra: string
): Promise<CodigoPLM[]> => {
  try {
    const res: AxiosResponse<CodigoPLM[]> = await client.get(
      `Servico/listarCodigoPLM?codigoEmpresa=${codigoEmpresa}&codigoObra=${codigoObra}`
    );

    if (Array.isArray(res)) {
      return res;
    } else {
      throw new Error("Dados recebidos não são um array");
    }
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa: " + error.message);
  }
};
export const getPrazoBI = async (prazoBI: string, codigoObra: string) => {
  try {
    const res = await client.get(
      `Servico/tabela-prazos?servicoBI=${prazoBI}&codigoObra=${codigoObra}`
    );
    return res;
  } catch (error: any) {
    throw new Error("Erro ao carregar os dados da empresa", error.message);
  }
};
