import { AppBar, Button } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import { Anexo } from "../../Interface/IAnexos";
import "./style.scss";

interface NavbarProps {
    numeroPedido: number;
    anexoAtualizado: Anexo[];
}

const Navbar: React.FC<NavbarProps> = ({ numeroPedido }) => {
    const location = useLocation();
    const { etapa, responsavelPelaEtapa, acao } = location.state || {};

    const isActive = (path: string) => location.pathname === path;

    return (
        <AppBar className='appBar'>
            <ul className='navItens'>
                <li style={{ marginRight: '20px' }}>
                    <Button
                        className={`nav-link ${isActive(`/visualizarPedido/${numeroPedido}`) ? 'active-link' : ''}`}
                        style={{ color: 'black' }}
                        component={Link}
                        to={`/visualizarPedido/${numeroPedido}`}
                        state={{ etapa, responsavelPelaEtapa, acao }}
                    >
                        Visualizar Pedido
                    </Button>
                </li>
                <li>
                    <Button
                        className={`nav-link ${isActive(`/aprovacoes/${numeroPedido}`) ? 'active-link' : ''}`}
                        style={{ color: 'black' }}
                        component={Link}
                        to={`/aprovacoes/${numeroPedido}`}
                        state={{ etapa, responsavelPelaEtapa, acao }}
                    >
                        {acao ? 'Aprovar Pedido' : 'Histórico De Aprovação'}
                    </Button>
                </li>
            </ul>
        </AppBar>
    );
};

export default Navbar;
