import { Button, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrcamentos } from "../../redux/pedido/sliceViewModelEntrada";

const ItemSemOrcamentoTeste: React.FC = () => {
    const dispatch = useDispatch();
    const novoPedido = useSelector((state: any) => state.pedidoSliceViewModelEntrada);
    const desabilitado = useSelector((state: any) => state.modeSlice.desabilitado);

    const handleClick = (value: boolean) => {
        dispatch(setOrcamentos({ ...novoPedido.orcamentos, itemSemOrcamento: value }));

    };
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = event.target;
        dispatch(setOrcamentos({ ...novoPedido.orcamentos, justificativa: value }));
    };

    return (
        <div>
            <p className="text-primary">
                Existe item sem orçamento nesse pedido?
            </p>
            <div className="gap-2">
                <Button
                    disabled={desabilitado}
                    style={{
                        borderRadius: '0.375rem',
                        textTransform: 'none',
                        borderColor: '#0d6efd',
                        backgroundColor: desabilitado && novoPedido.orcamentos.itemSemOrcamento === false ? '#0d6dfcd2'
                            : novoPedido.orcamentos.itemSemOrcamento === false ? '#0d6efd' : 'transparent',
                        color: novoPedido.orcamentos.itemSemOrcamento === false ? '#fff' : '#0d6efd',
                        padding: '0.35rem 0.75rem',
                        minWidth: 'auto',
                        marginRight: '8px',
                        fontSize: '15px',
                        marginBottom: '20px',
                    }}
                    onClick={() => handleClick(false)}
                    variant='outlined'
                >
                    Não
                </Button>
                <Button
                    disabled={desabilitado}
                    style={{
                        borderRadius: '0.375rem',
                        textTransform: 'none',
                        padding: '0.35rem 0.75rem',
                        minWidth: 'auto',
                        borderColor: '#0d6efd',
                        fontSize: '15px',
                        marginBottom: '20px',
                        backgroundColor: desabilitado && novoPedido.orcamentos.itemSemOrcamento === true ? '#0d6dfcd2' :
                            novoPedido.orcamentos.itemSemOrcamento === true ? '#0d6efd' : 'transparent',
                        color: novoPedido.orcamentos.itemSemOrcamento === true ? '#fff' : '#0d6efd',
                    }}
                    onClick={() => handleClick(true)}
                    variant='outlined'
                >
                    Sim
                </Button>
            </div>
            {
                novoPedido.orcamentos.itemSemOrcamento === true && (
                    <div className="formulario">
                        <TextField
                            disabled={desabilitado}
                            value={novoPedido.orcamentos.justificativa}
                            multiline
                            rows={2}
                            slotProps={{ inputLabel: { shrink: false, } }}
                            onChange={handleInputChange}
                            className="inputDetalhamento"
                            helperText={novoPedido.orcamentos.justificativa === '' ? 'A justificativa é obrigatória.' : ''}
                            label={<>Justificativa <span style={{ color: 'red' }}>*</span></>}
                        ></TextField>
                    </div>
                )
            }
        </div>
    );
};

export default ItemSemOrcamentoTeste;
